.atlas_bordered_tab {
}

.atlas_tab {
}

.atlas_tab :global(.ant-tabs-nav .ant-tabs-nav-wrap) {
  align-self: center;
  color: var(--blue-gray-500);
  font-weight: 600;
}

.atlas_tab :global(.ant-tabs-ink-bar) {
  background-color: var(--dark-blue-800);
}

.atlas_tab:global(.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab) {
  padding: 4px 2px;
}

.atlas_tab :global(.ant-tabs-tab.ant-tabs-tab-disabled) {
  color: var(--blue-gray-300);
}

.atlas_tab :global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:hover) {
  color: var(--blue-gray-500);
  background: var(--blue-gray-100);
}

.atlas_tab
  :global(
    .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn
  ),
.atlas_tab :global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:focus),
.atlas_tab
  :global(
    .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:active
  ) {
  color: var(--dark-blue-800);
}

.atlas_tab :global(.ant-tabs-nav::before) {
  border-color: var(--blue-gray-200);
}

.atlas_tab:not(.atlas_bordered_tab) :global(.ant-tabs-nav::before) {
  border: none;
}
