.warning_text {
  display: flex;
  color: var(--amber-600);
  margin-top: 0.2rem;
  align-items: center;
}

.warning_text > div {
  padding-top: 2px;
  color: var(--amber-600);
  margin-left: 0.5rem;
}
