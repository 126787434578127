.atlas_tag {
  cursor: default;
  display: inline-flex;
  font-size: 12px;
  white-space: nowrap;
  padding: 4px 6px;
  border-radius: 4px;
  font-family: var(--font-family);
  font-weight: var(--font-med);

  & :global(.ant-space-item) {
    line-height: initial;
  }
}

.atlas_tag_with_icon:hover {
  cursor: pointer;
}

.round {
  font-weight: var(--font-reg);
  padding: 4px 12px;
  height: 24px;
  border-radius: 12px;
}

.default {
  color: var(--blue-gray-700) !important;
  background-color: var(--blue-gray-100) !important;
}

.primary {
  color: var(--dark-blue-700) !important;
  background-color: var(--dark-blue-50) !important;
}

.success {
  color: var(--green-700) !important;
  background-color: var(--green-50) !important;
}

.error {
  color: var(--red-700) !important;
  background-color: var(--red-100) !important;
}

.warning {
  color: var(--amber-600) !important;
  background-color: var(--amber-50) !important;
}

// colors for otr statuses
.orange {
  color: #f69c20 !important;
  background-color: #fff6e9 !important;
}

.blue {
  color: #374255 !important;
  background-color: #e0f4fe !important;
}

.purple {
  color: #606 !important;
  background-color: #f0e6f0 !important;
}

.green {
  color: #35746e !important;
  background-color: #0f03 !important;
}

.dark_green {
  color: #35746e !important;
  background-color: #ddf3e3 !important;
}

.grey {
  color: #000 !important;
  background-color: #e6e6e6 !important;
}

.red {
  color: #f00 !important;
  background-color: #fcc !important;
}
