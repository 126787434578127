.item {
  padding: 1rem 0 0;
}

.no_padding {
  /* prevent lint errors */
}

.item.no_padding {
  padding: 0;
}

.title,
.text {
  display: block;
}

.title {
  color: var(--pitch-3);
  padding: 0 0 0.5rem;
  text-transform: capitalize;
}

.text {
  color: var(--text-color);
  font-size: 1rem;
}

.icon {
  margin-left: 0.5rem;
}
