.atlas_description_item :global(.ant-statistic-title) {
  font-size: 14px;
  color: var(--blue-gray-500);
}

.atlas_description_item :global(.ant-statistic-content) {
  font-size: 14px;
  color: var(--blue-gray-900);
}

.wrapper {
  position: relative; /* For positioning hover text */
  background-color: #f9fafc;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.hover_text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--blue-gray-800);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.wrapper:hover .hover_text {
  visibility: visible;
  opacity: 1;
}

.title_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--blue-gray-500);
}

.title_left {
  flex: 1;
  text-align: left;
}

.title_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.title_right_text {
  display: inline-block;
}

.title_right_icon {
  display: inline-block;
  color: var(--red-600);
}

.title_right_icon .anticon {
  display: inline-block;
  color: var(--red-600);
}

.atlas_description_item {
  width: 100%;
}
