:root {
  --deg: 0deg;
  --scale: 1;
  --padding: 0;
  --z-index: 110;
}

@keyframes fade-in-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.document_view_modal {
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: fade-in-opacity;
  animation-timing-function: ease-in;
  display: grid;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 110;
}

.has_side_panel {
  grid-template-columns: 1fr 500px;
}
