.radio_group {
  display: block;
}

/* properly position horizontal radio inputs */
.radio_horizontal {
  margin: 0.8rem 0 0;
}

.radio_vertical {
  /* redundant css to fix eslint bug */
  display: block;
}

/* properly position vertically stacked radio inputs */
.radio_vertical :global(.ant-radio-wrapper) {
  display: block;
  margin: 0 0 0.4em;
}

.label {
  vertical-align: top;
  display: inline-block;
}

.title,
.subtitle {
  display: block;
  padding: 0;
}

.subtitle {
  color: var(--pitch-3);
  font-size: var(--font-size-sml);
}

.highlight {
  border: 1px solid var(--red-600);
  border-radius: 0.25rem;
}
