.select {
  padding: 0.5rem;
}

.select :global(.ant-dropdown-menu:empty) {
  padding: 0;
}

.select :global(.ant-dropdown-menu:empty::after) {
  display: none;
}

.search_input {
  margin-bottom: 4px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.search_input input {
  background-color: white;
  font-size: var(--font-size-sml);
  line-height: 30px !important;
  padding: 0 0.75rem 0 1.8rem !important;
}

.search_input input::input-placeholder {
  color: var(--blue-gray-400);
}

.filter_submenu {
  min-width: 12rem;
  max-height: 12.5rem;
  overflow-y: auto !important;
  border: none;
}

.filter_submenu li {
  line-height: 2rem !important;
  height: 2rem !important;
  font-size: var(--font-size-sml) !important;
  margin-bottom: 0 !important;
  padding: 0 0.5rem !important;
  color: var(--pitch-24);
  display: flex;
}

.filter_submenu :global(.ant-menu-item-selected) {
  background-color: var(--blue-gray-100) !important;
  border-radius: 3px;
  color: var(--pitch-24);
}

.icon {
  top: 0.3rem;
}

.search_icon {
  width: 1rem;
  height: 1rem;
  color: #979797;
}

.check_all {
  padding: 0.5rem;
}

.check_all :global(.ant-checkbox + span) {
  margin-left: 10px;
  font-weight: var(--font-med);
}

.check_all :global(.ant-checkbox-indeterminate .ant-checkbox-inner::after) {
  background-color: var(--pitch-24);
}
