.address_select_container {
  text-align: left;
  position: relative;
}

.label {
  display: inline-block;
}

.address_select {
}

.address_select :global(.ant-select-selector) {
  width: 100%;
  height: 40px !important;
  padding: 0 11px !important;
  color: var(--pitch-1);
  font-size: 1rem;
}

.address_select :global(.ant-select-selector .ant-select-selection-placeholder),
.address_select :global(.ant-select-selector .ant-select-selection-item) {
  line-height: 38px !important;
  pointer-events: none;
}

.error {
  color: var(--red-1);
}

.address_select.error :global(.ant-select-selector) {
  border-color: var(--red-1) !important;
}
