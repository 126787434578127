.body {
  background: var(--bg-light);
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.body_wrapper {
  position: relative;
  padding-left: 3.5rem;
}

.side_header {
  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-rows: auto auto;
  align-self: stretch;
  background: var(--pitch-1);
  color: white;
  width: 3.5rem;
  padding: 0.5rem;
}
