.error {
}

.error label {
  color: var(--red-1);
}

.error input {
  border-color: var(--red-1);
}

.tooltip {
}

.tooltip :global(.ant-tooltip-inner) {
  background-color: var(--red-1);
}

.tooltip :global(.ant-tooltip-arrow > .ant-tooltip-arrow-content) {
  background-color: var(--red-1);
}
