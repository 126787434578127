@import './media.scss';

:root {
  /* Color Ramp */

  /* Blues */
  --blue-24: hsl(217deg 90% 24%);
  --blue-48: hsl(217deg 90% 48%);
  --blue-60: hsl(217deg 90% 60%);
  --blue-72: hsl(217deg 90% 72%);
  --blue-88: hsl(217deg 90% 88%);
  --blue-96: hsl(217deg 90% 96%);
  --blue-98: hsl(217deg 82% 98%);
  --blue-400: hsl(209deg 100% 55% / 100%);

  /* Pitches */
  --pitch-12: hsl(210deg 17% 12%);
  --pitch-24: hsl(210deg 17% 24%);
  --pitch-40: hsl(210deg 17% 40%);
  --pitch-64: hsl(210deg 17% 64%);
  --pitch-88: hsl(210deg 17% 88%);
  --pitch-96: hsl(210deg 17% 96%);
  --pitch-98: hsl(210deg 17% 98%);

  /* Reds */
  --red-24: hsl(353deg 98% 24%);
  --red-40: hsl(353deg 98% 40%);
  --red-56: hsl(353deg 98% 56%);
  --red-72: hsl(353deg 98% 72%);
  --red-88: hsl(353deg 98% 88%);
  --red-96: hsl(353deg 80% 96%);
  --red-400: hsl(0deg 91% 71% / 100%);
  --red-50: hsl(0deg 86% 97% / 100%);
  --red-600: hsl(0deg 72% 51% / 100%);
  --red-700: hsl(0deg 74% 42% / 100%);

  /* Yellows */
  --yellow-24: hsl(50deg 100% 24%);
  --yellow-48: hsl(50deg 85% 48%);
  --yellow-64: hsl(50deg 85% 64%);
  --yellow-72: hsl(50deg 80% 72%);
  --yellow-88: hsl(50deg 80% 88%);
  --yellow-96: hsl(50deg 76% 96%);

  /* Greens */
  --green-24: hsl(167deg 72% 24%);
  --green-40: hsl(167deg 60% 40%);
  --green-56: hsl(167deg 48% 56%);
  --green-72: hsl(167deg 48% 72%);
  --green-88: hsl(167deg 48% 88%);
  --green-96: hsl(167deg 48% 96%);

  /* Oranges */
  --orange-55: hsl(35deg 92% 55%);
  --orange-65: hsl(35deg 100% 65%);

  /* Blue Gray */
  --blue-gray-100: hsl(210deg 40% 96%);
  --blue-gray-700: hsl(215deg 25% 27%);
  --blue-gray-800: hsl(217deg 33% 17%);

  /* Dark Blue */
  --dark-blue-900: hsl(215deg 100% 6%);
  --dark-blue-800: hsl(210deg 100% 16%);

  /* Yellow Amber */
  --yellow-amber-50: hsl(48deg 100% 96.1%);

  /* Green Teal */
  --green-teal-50: hsl(166deg 76% 97%);
  --green-teal-600: hsl(175deg 84% 32%);
  --green-teal-700: hsl(175deg 77% 26%);

  /* Color Tokens */
  --base-blue: var(--blue-60);
  --base-pitch: var(--pitch-24);
  --base-orange: var(--orange-55);
  --base-yellow: var(--yellow-48);
  --base-green: var(--green-40);
  --base-red: var(--red-40);

  /* Text Colors */
  --textColor-headings: var(--pitch-12);
  --textColor-default: var(--base-pitch);
  --textColor-muted: var(--pitch-64);
  --textColor-success: var(--green-24);
  --textColor-warning: var(--yellow-24);
  --textColor-error: var(--red-24);
  --textColor-positive: var(--blue-24);

  /* Primary Button Text Colors */
  --textColor-primaryButton: white;
  --textColor-primaryButton_default: var(--textColor-primaryButton);
  --textColor-primaryButton_hover: var(--blue-48);
  --textColor-primaryButton_focus: var(--blue-48);

  /* Secondary Button Text Colors */
  --textColor-secondaryButton: var(--base-blue);
  --textColor-secondaryButton_default: var(--textColor-secondaryButton);
  --textColor-secondaryButton_hover: var(--blue-48);
  --textColor-secondaryButton_focus: var(--blue-48);

  /* Tertiary Button Text Colors */
  --textColor-tertitaryButton: var(--base-pitch);
  --textColor-tertitaryButton_default: var(--textColor-tertitaryButton);
  --textColor-tertitaryButton_hover: var(--pitch-12);
  --textColor-tertitaryButton_focus: var(--pitch-12);

  /* Inline Button Text Colors */
  --textColor-inlineButton: var(--pitch-40);
  --textColor-inlineButton_default: var(--textColor-inlineButton);
  --textColor-inlineButton_hover: var(--base-pitch);
  --textColor-inlineButton_focus: var(--base-pitch);

  /* Background Colors */
  --bgColor-positive: var(--blue-96);
  --bgColor-selected: var(--blue-98);
  --bgColor-success: var(--green-96);
  --bgColor-warning: var(--yellow-96);
  --bgColor-error: var(--red-96);
  --bgColor-neutral: var(--pitch-96);
  --bgColor-default: var(--pitch-98);

  /* Border Colors */
  --borderColor-default: var(--pitch-88);
  --borderColor-neutral: var(--pitch-64);
  --borderColor-warning: var(--base-yellow);
  --borderColor-error: var(--base-red);
  --borderColor-positive: var(--base-blue);
  --borderColor-success: var(--base-green);

  /* Text Input Border Colors */
  --borderColor-textInput_default: var(--borderColor-default);
  --borderColor-textInput_hover: var(--base-pitch);
  --borderColor-textInput_focus: var(--borderColor-textInput_hover);

  /* Primary button Border Colors */
  --borderColor-primaryButton_default: var(--borderColor-positive);
  --borderColor-primaryButton_hover: var(--blue-48);
  --borderColor-primaryButton_focus: var(--borderColor-primaryButton_hover);

  /* Secondary button Border Colors */
  --borderColor-secondaryButton_default: var(--borderColor-positive);
  --borderColor-secondaryButton_hover: var(--blue-48);
  --borderColor-secondaryButton_focus: var(--borderColor-secondaryButton_hover);

  /* tertiary button Border Colors */
  --borderColor-tertitaryButton_default: transparent;
  --borderColor-tertitaryButton_hover: var(--pitch-12);
  --borderColor-tertitaryButton_focus: var(--borderColor-tertitaryButton_hover);

  /* Shadows */
  --shadow-0: 0 0 0 0 rgb(0 0 0 / 0%);
  --shadow-4: 0 4px 8px -4px rgb(0 0 0 / 12%);
  --shadow-8: 0 8px 16px -4px rgb(0 0 0 / 12%);
  --shadow-16: 0 16px 32px -8px rgb(0 0 0 / 12%), 0 6px 12px -6px rgb(0 0 0 / 12%);
  --shadow-32: 0 32px 48px -8px rgb(0 0 0 / 12%), 0 6px 12px -6px rgb(0 0 0 / 12%);

  /* Deprecated Colors Variables */
  --blue-1: var(--blue-48);
  --blue-2: var(--blue-60);
  --blue-3: var(--blue-72);
  --blue-4: var(--blue-88);
  --blue-5: var(--blue-96);
  --blue-6: var(--blue-98);
  --pitch-1: var(--pitch-12);
  --pitch-2: var(--pitch-24);
  --pitch-3: var(--pitch-64);
  --pitch-4: var(--pitch-88);
  --pitch-5: var(--pitch-96);
  --green-1: var(--green-40);
  --green-2: var(--green-56);
  --green-3: var(--green-72);
  --green-4: var(--green-88);
  --green-5: var(--green-96);
  --red-1: var(--red-40);
  --red-2: var(--red-56);
  --red-3: var(--red-72);
  --red-4: var(--red-88);
  --red-5: var(--red-96);
  --yellow-1: var(--yellow-48);
  --yellow-2: var(--yellow-64);
  --yellow-3: var(--yellow-72);
  --yellow-4: var(--yellow-88);
  --yellow-5: var(--yellow-96);
  --orange-1: var(--orange-55);
  --orange-2: var(--orange-65);
  --antd-anchor: #40a9ff;
  --antd-border: var(--pitch-88);

  /* Colors */
  --bg-light: var(--pitch-98);
  --bg-soft: rgb(26 30 35 / 12%);
  --border: var(--antd-border);
  --text-color: var(--pitch-2);
  --header-bg: #222930;
  --modal-text: hsl(212deg 16% 25%);
  --color-disabled: #f5f5f5;
  --suffix-color: rgb(0 0 0 / 25%);

  /* Deprecated */
  --sidebar: 15rem;
  --shadow: var(--shadow-4);
  --shadow-2: var(--shadow-8);
  --shadow-focused: 0 0 0 2px var(--pitch-88);
  --shadow-focused-error: 0 0 0 2px var(--red-96);

  /* container for setting consistent page width */
  --container-small: 48rem;
  --container-med: 72rem;
  --container: 1440px;
  --container-padding: 1rem;

  /* ATLAS Nav Variables */
  --atlas-width-expanded: 16.5rem;
  --atlas-width-collapsed: 3.5rem;
  --color-atlas-bg: rgb(26 30 35 / 50%);

  /* header / footer heights */
  --header-height: 4.5rem;
  --footer-height: 5.25rem;

  /* transition */
  --duration: 0.3s;
  --duration-short: 0.15s;
  --cubic: cubic-bezier(0.42, 0, 0.58, 1);

  /* z-index vars */
  --z-index-neg: -1;
  --z-index-background: 0;
  --z-index-content: 100;
  --z-index-expand-ad: 200;
  --z-index-float-ad: 300;
  --z-index-popup: 400;
  --z-index-floating: 500;
  --z-index-nav: 600;
  --z-index-overlay: 700;

  /* grid */
  --grid-gap: 2rem;

  /* rounded-corners border-radius */
  --rounded-corners: 99px;
  --semi-rounded-corners: 12px;
}

/* ATLAS Nav media variables */
@media (--mobile-med) {
  :root {
    --atlas-width-expanded: 100%;
  }
}
