.footer {
  height: 48px;
  display: grid;
  grid-template-columns: 1fr repeat(2, max-content);
  grid-column-gap: 0.5rem;
  align-content: center;
  border-top: 1px solid var(--pitch-88);
  padding: 0 0.5rem;
}

.reset {
  font-family: Averta, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-sml);
  color: var(--pitch-24);
  width: 2.4rem;
}

.cancel {
  font-family: Averta, sans-serif;
  font-weight: 600;
  font-size: var(--font-size-sml) !important;
  line-height: 1rem !important;
  width: 4rem;
  border: 1px solid var(--pitch-24) !important;
  padding: 0.25rem 0.75rem !important;
  color: var(--pitch-24) !important;
}

.apply {
  font-family: Averta, sans-serif;
  font-weight: 600;
  font-size: var(--font-size-sml) !important;
  line-height: 1rem !important;
  width: 4rem;
  padding: 0.25rem 0.75rem !important;
  color: white;
  background-color: var(--dark-blue-900) !important;
}
