.content_wrapper {
  display: flex;
}

.content_wrapper > .content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content_wrapper > .copy {
  display: none;
  margin-left: 0.75rem;
  border: none;
}

.content_wrapper:hover > .copy {
  display: block;
}
