.input {
  min-width: 12rem;
  padding: 0.5rem;
  margin: 0;
}

.input input {
  line-height: 2rem !important;
  padding: 0 0.75rem 0 1.8rem !important;
  font-size: var(--font-size-sml) !important;
}

.input input::placeholder {
  color: #979797;
  font-size: var(--font-size-sml);
}

.icon {
  height: 2rem;
  line-height: 2rem;
  margin-left: 0.5rem;
}

.search_icon {
  width: 1rem;
  height: 1rem;
  color: #979797;
}

.textarea {
  background-color: var(--bg-light);
  font-size: var(--font-size-sml);
  height: 8rem;
  padding: 0.5rem;
  width: 20rem;
  line-height: 2rem !important;
}

.textarea_container {
  margin-bottom: 0;
  padding: 0.5rem;
}

.textarea_container p {
  bottom: 0.6rem;
  right: 0.6rem;
}

.padding_0 {
  padding: 0;
}
