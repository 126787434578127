.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: var(--atlas-width-collapsed);
  z-index: 110;
  background: var(--pitch-1);
  padding: 1rem;
  transform-origin: center left;
  transition: all var(--duration) var(--cubic);
  overflow: hidden;
}

.header {
  position: absolute;
  top: 1rem;
  width: 1rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: var(--grid-gap);
}

.profile_details_section {
  margin-top: calc(85vh - 120px);
  margin-left: -4px;
  background: #3d4dac;
  border: 1px solid #2b3679;
  border-radius: 50%;
  width: 32px !important;
  height: 32px !important;
  line-height: 28.287px;
  padding: 1.5px !important;
  text-align: center !important;
}

.profile_details_section:hover {
  cursor: pointer;
  background: #33418f;
  border: 1px solid #222a5f;
}

.profile_details_link {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px !important;
  color: white;
}

.profile_details_link:hover {
  color: white;
}

.toggle {
  background: none;
  border: none;
  align-self: center;
  color: var(--pitch-3);
  cursor: pointer;
  padding: 0;
}

.toggle:hover,
.toggle:focus {
  outline: 0;
  color: var(--blue-5);
}

/* stylelint-disable selector-class-pattern  */

.nav:global(.nav-enter) {
  width: var(--atlas-width-collapsed);
}

.nav:global(.nav-enter-active) {
  transition: all 0.2s var(--cubic);
  width: var(--atlas-width-expanded);
}

.nav:global(.nav-enter-done) {
  width: var(--atlas-width-expanded);
}

.nav:global(.nav-exit) {
  width: var(--atlas-width-expanded);
}

.nav:global(.nav-exit-active) {
  transition: all 0.2s var(--cubic) 0.1s;
  width: var(--atlas-width-collapsed);
}

.nav:global(.nav-exit-done) {
  width: var(--atlas-width-collapsed);
}

.nav :global(.menu-slide-enter) {
  opacity: 0;
}

.nav :global(.menu-slide-enter-active) {
  opacity: 0;
  transition: opacity 0.1s var(--cubic) 0.1s;
}

.nav :global(.menu-slide-exit) {
  opacity: 1;
}

.nav :global(.menu-slide-exit-active) {
  transition: opacity 0.1s var(--cubic);
  opacity: 0;
}

.nav :global(.menu-slide-exit-done) {
  display: none;
}

.nav :global(.toggle-enter) {
  opacity: 0;
}

.nav :global(.toggle-enter-active) {
  opacity: 1;
  transition: opacity 0.1s var(--cubic) 0.2s;
}

.nav :global(.toggle-enter-active-done) {
  opacity: 1;
}

.nav :global(.toggle-exit) {
  opacity: 1;
}

.nav :global(.toggle-exit-active) {
  opacity: 0;
  transition: opacity 0.1s var(--cubic);
}

.letter_mark {
  width: 22px;
  height: 22px;
}
