.container {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.container.large {
  font-size: 2.5rem;
  line-height: 3rem;
}

.number {
  display: block;
  line-height: 1;
  padding: 0 0.25rem;
  text-transform: uppercase;
  border-radius: 0.25rem;
}

.number.large {
  font-size: 2.5rem;
  padding: 0.5rem 0.75rem 0 0.5rem;
  font-weight: var(--font-med);
}

.number.loaded {
  /* prevent style lint error */
}

.type {
  display: block;
  line-height: 1;
  padding: 0.1rem 0.25rem 0;
  text-transform: uppercase;
  border-radius: 0.25rem;
}

.type.large {
  font-size: 2.5rem;
  padding: 0.5rem 0.75rem 0 0.5rem;
  font-weight: var(--font-med);
}

.size {
  display: block;
  line-height: 1;
  padding: 0.1rem 0.25rem 0;
  text-transform: uppercase;
  border-radius: 0.25rem;
}

.size.large {
  font-size: 2.5rem;
  padding: 0.5rem 0.75rem 0 0.5rem;
  font-weight: var(--font-med);
}

.icon {
  margin-top: -0.1rem;
}

.icon.loaded {
  color: var(--text-color);
}

.icon.large {
  height: 2.5rem;
}

.icon.large:global(.anticon) {
  height: 3.5rem;
  width: 3.5rem;
}

.button {
  margin-left: 0.25rem;
}

.button.large {
  font-size: 2.5rem;
  line-height: 3rem;
}

.warning_icon {
  color: white;
  background: var(--yellow-1);
  padding: 0.25rem;
  font-size: 0.8rem;
  margin-right: 0;
  margin-left: 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.warning_icon.large {
  font-size: 1.5rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
}

.warning {
  /* prevent style lint error */
}

.warning :global(.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow) {
  border-top-color: var(--yellow-2) !important;
  border-left-color: var(--yellow-2) !important;
}

.warning :global(.ant-popover-inner) {
  box-shadow: inset 0 0 0 2px var(--yellow-2);
}
