.single_action {
  padding: 0 1rem;
  font-size: 12px;
  line-height: 12px;
  font-weight: var(--font-med);
}

.single_action span {
  width: 12px;
  height: 12px;
}

.action_cell {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1rem;
}

.action_button {
  font-size: 12px;
  height: 20px;
}
