@import './forms.scss';
@import './media.scss';

:global(.ant-input-search) {
  border-radius: 0.25rem;
}

:global(.ant-select) {
  width: 100% !important;
}

:global(.ant-select .ant-select-selector) {
  background: var(--input-bg) !important;
  font-size: 1rem;
  line-height: 1.5rem !important;
  border-radius: 4px !important;
  border: 1px solid var(--borderColor-textInput_default) !important;
}

:global(.ant-select:hover .ant-select-selector) {
  background: var(--input-bg) !important;
  font-size: 1rem;
  line-height: 1.5rem !important;
  border-radius: 4px !important;
  border: 1px solid var(--borderColor-textInput_hover) !important;
}

:global(.ant-select.ant-select-focused .ant-select-selector) {
  background: white !important;
  font-size: 1rem;
  line-height: 1.5rem !important;
  border-radius: 4px !important;
  border: 1px solid var(--borderColor-textInput_focus) !important;
  box-shadow: 0 0 0 2px var(--pitch-88) !important;
}

:global(.ant-select:not(.ant-select-disabled) .ant-select-selector .ant-select-selection-search),
:global(.ant-select:not(.ant-select-disabled) .ant-select-selector .ant-select-selection-item) {
  color: var(--textColor-default);
  font-weight: 400;
}

:global(.ant-input::placeholder),
:global(.ant-select-selection-placeholder),
:global(.ant-calendar-range-picker-input::placeholder),
:global(.ant-picker input::placeholder) {
  color: var(--pitch-3) !important;
  opacity: 0.4 !important;
}

:global(.ant-picker) {
  width: 100%;
  border-radius: 0.25rem;
  background-color: var(--input-bg);
  transition: all 0.2s var(--cubic);
}

:global(.ant-picker-focused) {
  border-color: var(--borderColor-textInput_focus);
  box-shadow: 0 0 0 2px var(--pitch-88);
  background-color: white;
}

:global(.ant-picker:hover) {
  border-color: var(--borderColor-textInput_hover);
}

:global(.ant-picker-dropdown .ant-picker-range-arrow) {
  display: none;
}

:global(.ant-picker-dropdown .ant-picker-panel-container) {
  border: 2px solid var(--pitch-3);
}

:global(.ant-radio-wrapper .ant-radio-inner) {
  border-color: var(--borderColor-neutral);
}

:global(.ant-radio-wrapper:hover .ant-radio-inner) {
  border-color: var(--borderColor-textInput_hover);
}

:global(.ant-radio-checked .ant-radio-inner) {
  border-color: var(--base-blue);
}

:global(.ant-radio-checked .ant-radio-inner::after) {
  background-color: var(--base-blue);
}

:global(.ant-radio-wrapper-checked:hover .ant-radio-inner) {
  border-color: var(--blue-48);
}

:global(.ant-radio-wrapper-checked:hover .ant-radio-inner::after) {
  background-color: var(--base-blue);
}

:global(.ant-radio-wrapper-checked .ant-radio-input:focus + .ant-radio-inner) {
  border-color: var(--blue-48);
}

:global(.ant-radio-wrapper-checked .ant-radio-input:focus + .ant-radio-inner::after) {
  background-color: var(--blue-48);
}

:global(.ant-timeline-item-content) {
  margin: inherit;
  padding: 0 0 0 18px;
}

:global(.ant-radio-button-wrapper) {
  width: 50%;
  text-align: center;

  /* had to do this to fix storybook */
  padding: 0.4rem 2rem !important;
  height: 40px !important;
}

:global(.ant-checkbox) {
  display: inline-block;
}

:global(.ant-tabs-tab) {
  margin-left: 0 !important;
}

:global(.ant-checkbox-wrapper) {
  display: block;
}

:global(.ant-checkbox-group) {
  display: grid;
}

:global(.ant-checkbox .ant-checkbox-inner) {
  border-color: var(--borderColor-neutral);
}

:global(.ant-checkbox:hover .ant-checkbox-inner) {
  border-color: var(--borderColor-textInput_hover);
}

:global(.ant-checkbox-wrapper:hover .ant-checkbox-inner) {
  border-color: var(--borderColor-textInput_hover);
}

:global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--borderColor-textInput_focus);
  box-shadow: 0 0 0 2px var(--pitch-88);
}

:global(.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner) {
  background-color: var(--base-blue);
  border-color: var(--base-blue);
}

:global(.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-inner) {
  background-color: var(--base-blue);
  border-color: var(--base-blue);
}

:global(.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner) {
  border-color: var(--blue-48);
  background-color: var(--blue-48);
}

:global(
    .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner
  ) {
  border-color: var(--blue-48);
  background-color: var(--blue-48);
}

:global(.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--blue-48);
  background-color: var(--blue-48);
  box-shadow: 0 0 0 2px var(--blue-88);
}

:global(.ant-tabs-nav) {
  margin: 0 !important;
}

:global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab) {
  padding: 0.5rem 1rem 1rem;
  margin-right: 1rem;
  transition: all var(--duration-short) var(--cubic);
}

:global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:hover),
:global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:focus) {
  color: var(--pitch-3);
  background: var(--pitch-5);
  box-shadow: inset 0 -2px 0 0 var(--pitch-4);
}

:global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn),
:global(
    .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:active
  ) {
  color: var(--pitch-1);
}

:global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active:hover),
:global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active:focus) {
  background: var(--blue-5);
}

@media print {
  :global(.ant-input) {
    border: 0;
  }
}

:global(.ant-pagination) {
  text-align: left;
  padding: 2rem 0 !important;
}

:global(.ant-table-pagination.ant-pagination) {
  margin: 0 2rem;
}

:global(.ant-pagination-item-link) {
  padding: 0;
  line-height: inherit;
}

:global(.ant-pagination-item a) {
  display: inline !important;
}

:global(.ant-popover-title) {
  border-bottom: none;
  font-weight: var(--font-bold);
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
  margin-bottom: 0.25rem;
}

:global(.ant-popover > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content) {
  background: var(--pitch-3);
}

:global(
    .ant-popover > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content::before
  ) {
  background: var(--pitch-3);
}

:global(.ant-popover-inner-content) {
  padding: 0;
}

:global(.ant-popover-inner) {
  padding: 1rem;
  border-radius: 0;
  border: solid 2px var(--pitch-3);
  box-shadow: var(--shadow-2);
}

@media (--mobile-med) {
  :global(.ant-popover-arrow) {
    transform: translateX(50vw) rotate(45deg);
  }
}

@media screen and (width <= 500px) {
  :global(.ant-popover-arrow) {
    transform: translateX(55vw) rotate(45deg);
  }
}

@media screen and (width <= 400px) {
  :global(.ant-popover-arrow) {
    transform: translateX(70vw) rotate(45deg);
  }
}

:global(.ant-modal-title) {
  font-weight: var(--font-med);
}

:global(.ant-modal-footer button + button) {
  margin-bottom: 0;
  margin-left: 8px;
}

:global(.ant-table-filter-dropdown .ant-checkbox-wrapper) {
  display: inline-flex;
}

:global(
    .ant-table-filter-dropdown
      .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled)
      .ant-checkbox-inner
  ) {
  background-color: #333d47 !important;
}

:global(.ant-table-filter-dropdown .ant-checkbox-inner) {
  border: 1px solid #333d47 !important;
}

:global(.ant-form-item-explain-error) {
  color: var(--red-600);
}
