.document_viewer {
  background-color: rgb(25 30 35 / 90%);
  display: flex;
  flex-direction: column;
}

.document_viewer_header {
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
}

.document_viewer_header p {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.document_viewer_body {
  height: 100%;
  padding: 1rem 5.5rem;
  position: relative;
}

.document_viewer_body iframe {
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document_viewer_body .prompt_message {
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 2rem;
  height: 100%;
  width: 100%;
}

.document_viewer_exit {
  width: 24px;
  height: 24px;
  color: #fafbfb;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
