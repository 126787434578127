.document_change {
  margin-right: 1rem;
}

.document_change button {
  width: 2.5rem;
  height: 2.5rem;
  padding: 7px;
  border-radius: 5px;
  display: inline;
}

.document_change button:first-child {
  margin-right: 0.5rem;
}
