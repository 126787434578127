.viewer_side_panel {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.viewer_side_panel .panel_header {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--pitch-4);
}

.viewer_side_panel .panel_header .left {
}

.viewer_side_panel .panel_header .right {
  justify-self: end;
}

.viewer_side_panel .panel_body {
  padding: 1rem 1.5rem;
  flex: auto;
  overflow: auto;
}

.panel_footer {
  padding: 1rem 1.5rem;
  width: 100%;
  border-top: 1px solid var(--pitch-4);
}
