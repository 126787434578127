.drag_upload_container {
  font-size: 1rem;
}

.drag_upload_container div:first-child {
  color: var(--pitch-40);
}

.drag_upload_container div:nth-child(2) {
  color: var(--pitch-64);
}

.browse_button {
  color: var(--blue-2);
  text-decoration-color: var(--blue-2);
}

:global(.ant-upload.ant-upload-drag) {
  background: var(--blue-6);
  border: 1px dashed var(--blue-4);
}
