.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.container button span {
  white-space: nowrap;
}

.upload_btn {
  margin-right: 1rem;
}

.message {
  color: var(--pitch-3);
}

.error_message {
  color: var(--red-1);
}

.label {
  font-weight: bolder;
  font-size: 1rem;
  display: block;
}

.label.error {
  color: var(--red-1);
  border-color: var(--red-1);
}

.tooltip {
}

.tooltip :global(.ant-tooltip-arrow > .ant-tooltip-arrow-content) {
  background-color: var(--red-1);
}

.tooltip :global(.ant-tooltip-inner) {
  background-color: var(--red-1);
}

.no_icon > span {
  margin-left: 0;
}
