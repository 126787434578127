.badge_mark {
  display: inline-block;
  text-align: center;
  border-radius: 0.8rem;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1rem;
  padding: 4px 12px 2px;
  box-sizing: content-box;
  color: var(--pitch-40);
  border: 1px solid var(--borderColor-default);
  background: white;
  cursor: default;
  white-space: nowrap;
}

.badge_mark > span {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.25rem;
}

.hand_icon {
  cursor: pointer;
}

.error {
  color: var(--red-2);
  border: 1px solid var(--red-4);
}

.success {
  color: var(--green-2);
  border: 1px solid var(--green-4);
}

.warning {
  color: var(--yellow-1);
  border: 1px solid var(--yellow-2);
}

.info {
  background-color: var(--yellow-5);
  border: 1px solid var(--borderColor-default);
}
