.logo {
  width: 6rem;
}

.auth {
  text-align: right;
  align-self: center;
  margin-left: 1rem;
}

.auth a {
  font-weight: var(--font-med);
  color: var(--pitch-3);
}

.auth a:hover,
.auth a:focus {
  color: white;
}

.link {
  color: var(--pitch-3);
  padding: 0.75rem 1.25rem;
  line-height: 1;
  font-weight: var(--font-med);
  background: none;
  font-size: var(--font-size-reg);
  transition: all var(--duration-short) var(--cubic);
}

.link:hover,
.link:focus {
  outline: none;
  color: var(--pitch-3);
  background: var(--pitch-5);
  border-radius: 0.25rem;
}

.link.active {
  color: var(--text-color);
}
