@import './media.scss';

.grid {
  display: grid;
  grid-gap: var(--grid-gap);
  word-break: break-word;
}

.two {
  composes: grid;
  grid-template-columns: 1fr 1fr;
}

.three {
  composes: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.four {
  composes: grid;
  grid-template-columns: repeat(4, 1fr);
}

.five {
  composes: grid;
  grid-template-columns: repeat(5, 1fr);
}

.six {
  composes: grid;
  grid-template-columns: repeat(6, 1fr);
}

.seven {
  composes: grid;
  grid-template-columns: repeat(7, 1fr);
}

.eight {
  composes: grid;
  grid-template-columns: repeat(8, 1fr);
}

.date_group {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
}

.container {
  max-width: var(--container);
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.container_no_padding {
  max-width: var(--container);
  margin: auto;
}

.container_center {
  composes: container;
  max-width: var(--container-small);
}

@media (--desktop) {
  .container {
    padding: 2rem;
  }

  .container_no_padding {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
