.body {
  background: var(--pitch-5);
}

.container {
  max-width: var(--container-med);
  margin: auto;
  padding: 1.5rem 2rem;
  display: flex;
  gap: 1rem;
}

.hero {
  color: var(--pitch-3);
  background: white;
  text-align: center;
  padding: 2rem 0 10.5rem;
}

.heading {
  display: block;
  padding-bottom: 0;
  text-align: left;
}

.subheading {
  display: block;
  font-weight: var(--font-med);
  font-size: 1.25rem;
  padding-top: 0;
  text-align: left;
}

.batch_logo {
  height: 52px;
  width: 52px;
}
