.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full {
  /* 10.75rem is combined height of header + footer */
  height: calc(100vh - 10.75rem);
}

.inline {
  display: inline-flex;
}
