:root {
  /* Ramp */

  /* Red */
  --red-900: #7f1d1d;
  --red-800: #991b1b;
  --red-700: #b91c1c;
  --red-600: #dc2626;
  --red-500: #ef4444;
  --red-400: #f87171;
  --red-300: #fca5a5;
  --red-200: #fecaca;
  --red-100: #fee2e2;
  --red-50: #fef2f2;

  /* Amber */
  --amber-900: #78350f;
  --amber-800: #92400e;
  --amber-700: #b45309;
  --amber-600: #d97706;
  --amber-500: #f59e0b;
  --amber-400: #fbbf24;
  --amber-300: #fcd34d;
  --amber-200: #fde68a;
  --amber-100: #fef3c7;
  --amber-50: #fffbeb;

  /* Green */
  --green-900: #134e4a;
  --green-800: #115e59;
  --green-700: #0f766e;
  --green-600: #0d9488;
  --green-500: #14b8a6;
  --green-400: #2dd4bf;
  --green-300: #5eead4;
  --green-200: #99f6e4;
  --green-100: #ccfbf1;
  --green-50: #f0fdfa;

  /* Blue */
  --dark-blue-900: #000e21;
  --dark-blue-800: #002851;
  --dark-blue-700: #004282;
  --dark-blue-600: #005db4;
  --dark-blue-500: #0077e6;
  --dark-blue-400: #1a91ff;
  --dark-blue-300: #4aa9ff;
  --dark-blue-200: #7cc2ff;
  --dark-blue-100: #addbff;
  --dark-blue-50: #dbf4ff;

  /* Indigo */
  --indigo-900: #14216b;
  --indigo-800: #0f24a4;
  --indigo-700: #2c48e7;
  --indigo-600: #405cf5;
  --indigo-500: #5972fe;
  --indigo-400: #7d91ff;
  --indigo-300: #a0aeff;
  --indigo-200: #bfc8ff;
  --indigo-100: #d9deff;
  --indigo-50: #eaedff;

  /* Blue Gray */
  --blue-gray-900: #0f172a;
  --blue-gray-800: #1e293b;
  --blue-gray-700: #334155;
  --blue-gray-600: #475569;
  --blue-gray-500: #64748b;
  --blue-gray-400: #94a3b8;
  --blue-gray-300: #cbd5e1;
  --blue-gray-200: #e2e8f0;
  --blue-gray-100: #f1f5f9;
  --blue-gray-50: #f8fafc;
}
