.card {
  margin: 0 0 2rem;
  background: white;
  padding: 1rem;
  border: 1px solid var(--pitch-4);
}

.separator {
  border-bottom: 1px solid var(--pitch-4);
  margin: 1rem -1rem;
}
