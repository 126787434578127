.atlas_link {
  color: var(--dark-blue-500);
  font-weight: var(--font-reg);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.atlas_link:hover {
  text-decoration: underline;
  color: var(--dark-blue-600);
}

.atlas_link:focus {
  color: var(--dark-blue-600);
}

.atlas_link.atlas_link_disabled {
  text-decoration: none;
  cursor: not-allowed;
  color: var(--blue-gray-300);
}
