/* ReadView */
.edit_button {
  position: absolute;
  display: block;
  margin: 0;
  padding: 0;
  appearance: none;
  background: transparent;
  border: none;
  line-height: 1;
  outline: 0;
}

.edit_button:focus + div {
  border: 1px solid var(--dark-blue-700);
}

.read_view_container {
  display: inline-block;
  border: 2px solid transparent;
  border-radius: 3px;
  transition: background 0.2s;
  cursor: text;
}

.read_view_container:hover {
  background: var(--blue-gray-200);
}

.read_view_container.read_view_disabled {
  cursor: not-allowed;
}

/* EditView */
.edit_view_container {
  position: relative;
}

.edit_view_container :global(.ant-form-item) {
  margin-bottom: 0;
}

.edit_view_container :global(.ant-form-item .ant-form-item-label label) {
  padding: 0;
  color: var(--blue-gray-700);
}

.edit_view_container .edit_view_buttons {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  z-index: 1;
  margin-top: 0.5rem;
}
