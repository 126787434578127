.icon {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.large {
  /* prevent lint error */
}

.icon.large {
  height: 2rem;
  width: 2rem;
}

.icon svg {
  width: 100%;
  height: 100%;
}

.rotate_90 {
  transform: rotate(90deg);
}

.rotate_270 {
  transform: rotate(-90deg);
}

.open_track {
  width: 21px;
  height: 21px;
}
