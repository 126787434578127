.btn,
.link,
.inline {
  transition: all 200ms var(--cubic);
}

/* For our large button size, we use 7px padding on top and bottom so with 1px border, total height is 40px */

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: var(--font-med);
  letter-spacing: normal;
  background: transparent;
  color: var(--textColor-tertiaryButton);
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  cursor: pointer;
}

.btn:hover {
  border-color: var(--borderColor-tertiaryButton_hover);
  color: var(--textColor-tertiaryButton_hover);
}

.btn:focus {
  outline: 0;
  border-color: var(--borderColor-tertiaryButton_focus);
  box-shadow: 0 0 0 2px var(--pitch-88);
  color: var(--textColor-tertiaryButton_focus);
}

.btn:active {
  background: var(--bgColor-neutral);
}

.btn.large {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 7px 1rem;
}

.btn.small {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.375rem 0.75rem;
}

button.icon_content {
  padding: 7px;
}

button.btn.large.icon_content {
  padding: 7px;
}

button.btn.small.icon_content {
  padding: 0.375rem;
}

.btn.primary {
  color: var(--textColor-primaryButton);
  background: var(--base-blue);
}

.btn.primary:hover {
  background: var(--blue-48);
  border-color: var(--borderColor-primaryButton_hover);
  color: var(--textColor-primaryButton);
}

.btn.primary:focus {
  background: var(--blue-48);
  box-shadow: 0 0 0 2px var(--blue-88);
  border-color: var(--borderColor-primaryButton_hover);
  color: var(--textColor-primaryButton);
}

.btn.primary:active {
  background: var(--blue-48);
  border-color: var(--borderColor-primaryButton_hover);
}

.btn.secondary {
  background: white;
  color: var(--textColor-secondaryButton);
  border: 1px solid var(--borderColor-secondaryButton_default);
}

.btn.secondary:hover {
  border-color: var(--borderColor-secondaryButton_hover);
  color: var(--textColor-secondaryButton_hover);
}

.btn.secondary:focus {
  box-shadow: 0 0 0 2px var(--blue-88);
  border-color: var(--borderColor-secondaryButton_hover);
  color: var(--textColor-secondaryButton_focus);
}

.btn.secondary:active {
  border-color: var(--borderColor-secondaryButton_hover);
  color: var(--textColor-secondaryButton_hover);
}

.btn.success {
  background: white;
  color: var(--base-green);
  border: 1px solid var(--base-green);
}

.btn.success:hover {
  border-color: var(--green-24);
  color: var(--textColor-success);
}

.btn.success:focus {
  box-shadow: 0 0 0 2px var(--green-88);
  border-color: var(--green-24);
  color: var(--textColor-success);
}

.btn.success:active {
  border-color: var(--green-24);
  color: var(--textColor-success);
}

.btn.warning {
  color: white;
  background: var(--yellow-1);
  border: 1px solid var(--yellow-1);
}

.btn.warning:hover {
  border-color: rgb(216 182 17);
}

.btn.warning:focus {
  border-color: rgb(216 182 17);
  box-shadow: 0 0 0 2px var(--yellow-4);
}

.btn.warning:active {
  border-color: rgb(216 182 17);
  box-shadow: 0 0 0 2px var(--yellow-4);
  background: rgb(216 182 17);
}

.btn.danger {
  color: white;
  background: var(--red-1);
  font-weight: var(--font-extrabold);
}

.btn.danger:hover {
  border-color: var(--red-4);
}

.btn.danger:focus {
  box-shadow: 0 0 0 2px var(--red-2);
}

.btn.danger:active {
  background: var(--red-2);
}

.btn.large.primary,
.btn.large.btn.secondary,
.btn.large.warning,
.btn.large.danger {
  padding: 0.8rem 2rem 1rem;
}

.btn.small.primary,
.btn.small.btn.secondary,
.btn.small.warning,
.btn.small.danger {
  padding: 0.5rem 1rem 0.4rem;
}

.btn:disabled,
.inline.disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

.link[disabled],
.link.disabled {
  color: var(--blue-2);
  cursor: not-allowed;
  opacity: 0.35;
}

.loading_text {
  margin-right: 1rem;
}

.icon {
  display: inherit;
  margin-left: 0;
  vertical-align: middle;
}

.icon + span {
  display: inline-block;
  margin-left: 0.5rem;
}

span + .icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

.btn.small .icon + span {
  display: inline-block;
  margin-left: 0.25rem;
}

.btn.small span + .icon {
  margin-right: 0;
  margin-left: 0.25rem;
}

.icon.empty {
  margin-right: 0;
  margin-left: 0;
}

.icon_loading {
  padding: 0.3rem 0;
}

.inline {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #567;
  display: inline-block;
  border-radius: 0.1875rem;
  padding: 0 0.25rem;
  text-decoration: underline;
  text-decoration-color: #567;
}

.inline:hover {
  text-decoration-color: var(--pitch-2);
  color: var(--pitch-2);
}

.inline:focus {
  background: var(--pitch-5);
  text-decoration-color: var(--pitch-2);
  color: var(--pitch-2);
  outline: 0;
}

.inline.link {
  color: var(--blue-2);
  padding: 0;
  text-decoration: underline var(--blue-4);
  transition: color 0s;
}

.inline.link:hover {
  color: var(--blue-2);
  text-decoration: underline var(--blue-2);
}

.inline.link:focus {
  background: var(--blue-5);
}

.inline.small {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttons.left {
  justify-content: flex-start;
}

.buttons .btn + .btn {
  margin-left: 1rem;
}

.buttons.left .btn + .btn {
  margin-left: 1rem;
}

.buttons.left.float .btn:last-child {
  margin-left: auto;
  margin-right: 0;
}

.buttons.float:not(.left) .btn,
.buttons.float:not(.left) .link {
  margin-left: 2rem;
  margin-right: 0;
}

.buttons.float:not(.left) .btn:first-child,
.buttons.float:not(.left) .link:first-child {
  margin-left: 0;
  margin-right: auto;
}

@media print {
  .buttons {
    display: none;
  }
}

.btn_popover {
}

.btn_popover :global(.ant-popover-arrow) {
  display: none;
}

.btn_popover_text {
  margin-right: 0.5rem;
}
