.popover_title {
  max-width: 360px;
}

.popover_content {
  font-size: 14px;
  height: 1.5rem;
  display: flex;
  align-items: center;
}

.popover_icon {
  width: 1rem;
  height: 1rem;
  color: var(--blue-gray-300);
  margin-right: 0.5rem;
  margin-top: -2px;
}

.active_popover_icon {
  color: #29a389;
}
