.header {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto 1fr;
  grid-gap: 0.5rem;
}

.logo {
  height: 1rem;
  display: block;
  margin: 0 auto;
}

.name {
  display: block;
  font-weight: var(--font-med);
  font-size: var(--font-size-med);
  padding: 0;
  color: var(--pitch-3);
  align-self: end;
}

.toggle {
  background: none;
  border: none;
  place-self: center end;
  color: var(--pitch-3);
  cursor: pointer;
  padding: 0;
}

.toggle:hover,
.toggle:focus {
  outline: 0;
  color: var(--blue-5);
}
