.atlas_text_area {
  position: relative;
}

.atlas_input,
.atlas_input:global(.ant-input-affix-wrapper) {
  position: relative;
  border-radius: 3px;
  background-color: #fff;
}

/* border */
.atlas_input,
.atlas_input:global(.ant-input-affix-wrapper),
.atlas_input :global(.ant-input-affix-wrapper) {
  border-color: var(--blue-gray-300);
}

.atlas_text_area:global(.ant-input),
.atlas_text_area :global(.ant-input) {
  border-color: var(--blue-gray-300);
}

/* color */
.atlas_input :global(.ant-input) {
  color: var(--blue-gray-700);
}

.atlas_input :global(.ant-input-show-count-suffix) {
  color: var(--blue-gray-400);
}

.atlas_input:global(.ant-input-affix-wrapper),
.atlas_input :global(.ant-input-affix-wrapper) {
  color: var(--blue-gray-700);
}

/* hover */
.atlas_input:global(.ant-input:hover),
.atlas_input:global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled)):hover,
.atlas_input :global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled)):hover {
  border-color: var(--blue-gray-800);
}

.atlas_text_area:global(.ant-input):hover,
.atlas_text_area :global(.ant-input):hover {
  border-color: var(--blue-gray-800);
}

/* focus */
.atlas_input:global(.ant-input):focus,
.atlas_input:global(.ant-input-focused),
.atlas_input:global(.ant-input-affix-wrapper):focus,
.atlas_input :global(.ant-input-affix-wrapper):focus,
.atlas_input:global(.ant-input-affix-wrapper-focused),
.atlas_input :global(.ant-input-affix-wrapper-focused) {
  border-color: var(--blue-gray-800);
  box-shadow: 0 0 0 2px var(--blue-gray-300);
}

.atlas_text_area:global(.ant-input:focus),
.atlas_text_area:global(.ant-input-focused),
.atlas_text_area :global(.ant-input:focus),
.atlas_text_area :global(.ant-input-focused) {
  border-color: var(--blue-gray-800);
  box-shadow: 0 0 0 2px var(--blue-gray-300);
}

/* disabled */
.atlas_input :global(.ant-input[disabled]) {
  color: var(--blue-gray-400);
  background: var(--blue-gray-50);
}

.atlas_input:global(.ant-input-affix-wrapper-disabled),
.atlas_input :global(.ant-input-affix-wrapper-disabled) {
  background: var(--blue-gray-50);
}

.atlas_text_area :global(.ant-input[disabled]) {
  background: var(--blue-gray-50);
  border-color: var(--blue-gray-300);
}

/* addon */
.atlas_input :global(.ant-input-group-addon) {
  color: var(--blue-gray-700);
  background-color: #fff;
  border-radius: 3px;
}

.atlas_input :global(.ant-input-group > .ant-input:first-child),
.atlas_input :global(.ant-input-group-addon:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.atlas_input :global(.ant-input-group > .ant-input:last-child),
.atlas_input :global(.ant-input-group-addon:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* clear icon */
.atlas_input :global(.anticon.ant-input-clear-icon),
.atlas_input :global(.ant-input-clear-icon) {
  color: var(--blue-gray-300);
}

.atlas_input :global(.anticon.ant-input-clear-icon):hover,
.atlas_input :global(.ant-input-clear-icon):hover {
  color: var(--blue-gray-700);
}

/* count */
.atlas_text_area:global(.ant-input-textarea-show-count::after) {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: none;
  margin-right: 0.5rem;
  margin-bottom: 0.3rem;
  z-index: 1;
}

/* error */
.atlas_input:global(
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper
  ),
.atlas_input:global(
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper
  ):hover {
  border-color: var(--red-600);
}

.atlas_input:global(
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper
  ):focus,
.atlas_input:global(
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper-focused
  ) {
  border-color: var(--red-600);
  box-shadow: 0 0 2px var(--red-800);
}

.atlas_input:global(.ant-input-group-wrapper-status-error .ant-input-group-addon) {
  border-color: var(--red-600);
}

.atlas_input
  :global(.ant-input-status-error:not(.ant-input-disabled, .ant-input-borderless).ant-input),
.atlas_input
  :global(.ant-input-status-error:not(.ant-input-disabled, .ant-input-borderless).ant-input):hover {
  border-color: var(--red-600);
}

.atlas_input
  :global(.ant-input-status-error:not(.ant-input-disabled, .ant-input-borderless).ant-input):focus,
.atlas_input
  :global(
    .ant-input-status-error:not(.ant-input-disabled, .ant-input-borderless).ant-input-focused
  ) {
  border-color: var(--red-600);
  box-shadow: 0 0 2px var(--red-800);
}

/* warning */
.atlas_input:global(
    .ant-input-affix-wrapper-status-warning:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper
  ),
.atlas_input:global(
    .ant-input-affix-wrapper-status-warning:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper
  ):hover {
  border-color: var(--amber-500) !important;
}

.atlas_input:global(
    .ant-input-affix-wrapper-status-warning:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper
  ):focus,
.atlas_input:global(
    .ant-input-affix-wrapper-status-warning:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper-focused
  ) {
  border-color: var(--amber-500);
  box-shadow: 0 0 2px var(--amber-800);
}

.atlas_input:global(.ant-input-group-wrapper-status-warning .ant-input-group-addon) {
  border-color: var(--amber-500);
}

.atlas_input
  :global(.ant-input-status-warning:not(.ant-input-disabled, .ant-input-borderless).ant-input),
.atlas_input
  :global(
    .ant-input-status-warning:not(.ant-input-disabled, .ant-input-borderless).ant-input
  ):hover {
  border-color: var(--amber-500);
}

.atlas_input
  :global(
    .ant-input-status-warning:not(.ant-input-disabled, .ant-input-borderless).ant-input
  ):focus,
.atlas_input
  :global(
    .ant-input-status-warning:not(.ant-input-disabled, .ant-input-borderless).ant-input-focused
  ) {
  border-color: var(--amber-500);
  box-shadow: 0 0 2px var(--amber-800);
}
