.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 15%);
  width: 350px;
  text-align: center;
}

.logo img {
  width: 50px;
  height: 50px;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem;
}

.subtitle {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.checkbox_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.checkbox_container input {
  margin-right: 0.5rem;
}

.verify_button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background 0.3s;
}

.verify_button:hover {
  background: #0056b3;
}

.footer {
  margin-top: 1rem;
  font-size: 0.75rem;
  color: #666;
  display: flex;
  justify-content: space-between;
}

.privacy_link {
  color: #007bff;
  text-decoration: none;
}

.privacy_link:hover {
  text-decoration: underline;
}

.qrcode_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qrcode_container {
  width: 100%;
}
