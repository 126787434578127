.atlas_menu :global(.ant-dropdown-menu-item),
.atlas_menu :global(.ant-dropdown-menu-submenu-title) {
  color: var(--blue-gray-900);
}

.atlas_menu :global(.ant-dropdown-menu-item:hover),
.atlas_menu :global(.ant-dropdown-menu-submenu-title:hover),
.atlas_menu :global(.ant-dropdown-menu-item.ant-dropdown-menu-item-active),
.atlas_menu :global(.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active),
.atlas_menu :global(.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active),
.atlas_menu :global(.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active) {
  background-color: var(--blue-gray-100);
}
