@import 'assets/css/common.scss';
@import 'assets/css/grid.scss';
@import 'assets/css/media.scss';

.flex_center {
  composes: flex_center;
}

.flex_center:nth-child(2) {
  margin-top: -11rem;
}

.product_list {
  max-width: var(--container-med);
  padding: 2rem 2rem 6rem;
  margin: auto 0;
}

.title {
  padding: 0 0 2rem;
}

.card_container {
  composes: three;
}

@media (--mobile) {
  .card_container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (--mobile-med) {
  .card_container {
    grid-template-columns: 1fr;
  }
}

.card_wrapper {
  display: block;
  padding: 0;
  align-self: stretch;
  transition: all var(--duration-short) var(--cubic);
  box-shadow: 0 8px 16px -4px rgb(0 0 0 / 15%);
}

.card_wrapper:hover,
.card_wrapper:focus {
  transform: translateY(-0.25rem);
  box-shadow: 0 24px 32px -16px rgb(0 0 0 / 15%);
  color: var(--text-color);
}

.card.card {
  margin: 0;
  padding: 2rem;
  text-align: center;
  border: none;
  height: 100%;
}

.heading {
  display: block;
  padding: 0;
}

.description {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--text-color);
}

.logo {
  display: inline-block;
}

.logo i {
  width: 3rem;
  height: 3rem;
  display: block;
  margin-bottom: 1rem;
}

.card_flag {
  display: flex;
  width: 100%;
  height: 2rem;
  align-items: center;
  justify-content: space-between;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.external_flag {
  color: #7c8fa2;
  margin-left: auto;
  margin-right: -1rem;
}

.coming_soon_flag {
  width: 6.75rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: center;
  background-color: #dbe0e6;
  color: #94a3b3;
  border-radius: 2px;
  margin-right: auto;
  margin-left: -1rem;
}

.coming_soon {
}

.product_theme .card.card:not(.coming_soon) {
  border-top: 0.25rem solid var(--orange-2);
}
