.search_input {
  margin-bottom: 0;
  padding: 6px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid var(--pitch-88);
}

.search_input input {
  background-color: var(--bg-light);
  font-size: var(--font-size-lrg);
  margin-bottom: 0;
  padding: 7px 12px;
}

.filter_submenu {
  min-width: 152px;
}

.filter_submenu_item {
  border: 1px solid var(--pitch-64);
  border-radius: 3px;
  max-height: 330px;
  overflow: auto;
  padding: 0;
}

.filter_submenu_item :global(.ant-menu) {
  max-height: none;
}

.popover {
}

.popover :global(.ant-popover-arrow) {
  display: none;
}

.popover :global(.ant-popover-inner) {
  border: 1px solid var(--pitch-64);
  border-radius: 3px;
  max-height: 330px;
  overflow: auto;
  padding: 0;
}

.popover :global(.ant-menu-item) {
  text-overflow: ellipsis;
}

.popover :global(.ant-menu-item-selected) {
  color: black;
}

.popover :global(.ant-popover-inner-content) {
  padding: 0;
}

.filter_btn {
  color: var(--pitch-24);
  display: inline-flex;
  font-size: var(--font-size-sml);
  font-weight: var(--font-med);
  line-height: 16px;
  margin-right: 8px;
  padding: 2px 8px 3px;
}

.filter_btn > span {
  padding-top: 4px;
}

.filter_btn_active {
  outline: 0;
  border-color: var(--pitch-64);
  box-shadow: 0 0 0 2px var(--pitch-88);
}

.filter_btn_icon {
  color: var(--pitch-64);
  vertical-align: -8px;
}

.textarea {
  background-color: var(--bg-light);
  font-size: var(--font-size-lrg);
  height: 142px;
  margin: 6px 6px 0;
  padding: 8px;
  width: 335px;
}

.tag {
  border: 1px solid var(--borderColor-default);
  border-radius: 12px;
  color: var(--base-pitch);
  display: inline-block;
  font-size: var(--font-size-sml);
  margin-right: 10px;
  padding: 2px 11px;
}

.pill_close {
  height: 1rem;
  margin-left: 4px;
  vertical-align: -4px;
  width: 1rem;
}
