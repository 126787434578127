/* handle fade out of loading overlay in output index.html */
:global(.overlay) {
  position: fixed;
  inset: 0;
  background: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s var(--cubic);
}

:global(.overlay.dark) {
  background: var(--color-atlas-bg);
}

@keyframes breathe {
  0%,
  100% {
    opacity: 0.2;
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }
}

:global(.overlay img) {
  width: 20vw;
  height: 20vw;
  max-width: 500px;
  animation: breathe 4s var(--cubic) infinite;
}

:global(.overlay.fade) {
  opacity: 0;
}

:global(.overlay.remove) {
  display: none;
}

:global(.overlay.loading) {
  opacity: 0.5;
}

/* stylelint-disable selector-class-pattern  */
:global(.scroll-lock) {
  overflow: hidden;
}
