/* @TODO migrate select style from ant.css to here */
.atlas_select {
}

.atlas_select_popup {
}

.atlas_select_popup :global(.ant-select-item-option-content) {
  text-overflow: ellipsis;
}
