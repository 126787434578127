.error {
}

.error :global(.ant-select-selector) {
  border-color: var(--borderColor-error);
}

.loading {
  padding-left: calc(50% - 1rem);
}
