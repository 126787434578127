@import 'assets/css/grid.scss';

.header {
  background: white;
}

.header_border {
  border-bottom: 1px solid var(--border);
}

.content {
  margin: 0 2rem;
  overflow: auto;
}

.inner {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.inner_with_banner {
  display: flex;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 0.7rem 0;
}

.title {
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.5rem 0.75rem 0 0;
}

.title_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.info {
  padding-bottom: 2rem;
}

.items {
  composes: grid;
  grid-template-columns: repeat(8, 1fr);
}

.warning {
  margin-left: 1rem;
  color: white;
  background: var(--yellow-2);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.popover {
  /* prevent lint errors */
}

.popover :global(.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow) {
  border-top-color: var(--yellow-2) !important;
  border-left-color: var(--yellow-2) !important;
}

.popover :global(.ant-popover-inner) {
  box-shadow: inset 0 0 0 2px var(--yellow-2);
}

.button_wrapper {
  display: grid;
  margin: auto 0 auto auto;
}

.button_wrapper_with_banner {
  margin-right: 0;
}

.banner_wrapper {
  width: 65%;
  margin-right: 0.75rem;
}

.bottom_banner_wrapper {
  width: 100%;
}

@media (--tablet) {
  .info {
    grid-template-columns: 2fr 1fr;
  }
}

@media (--mobile) {
  .info {
    grid-template-columns: 1fr;
  }

  .items {
    grid-template-columns: 1fr 1fr;
  }
}

.pre_title_wrapper {
  display: grid;
}

.side_panel_button {
  position: absolute;
  top: 1.5rem;
  right: 0;
  padding: 0 !important;
  height: 40px;
  border-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
