.atlas_collapse,
.atlas_collapse * {
  border-color: var(--blue-gray-200) !important;
}

.atlas_collapse {
  background-color: #fff;
  color: var(--blue-gray-900);
  border: none;
  border-top: 1px solid;
}
