.atlas_steps {
}

.atlas_steps :global(.ant-steps-item-title) {
  font-size: 14px;
}

.atlas_steps :global(.ant-steps-item-description) {
  font-size: 12px;
}

.atlas_steps :global(.ant-steps-item-icon) {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  margin: 4px 8px 0 0;
}

.atlas_steps:global(.ant-steps-small .ant-steps-item-icon) {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
}

.atlas_steps
  :global(
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after
  ),
.atlas_steps
  :global(
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after
  ) {
  background: var(--blue-gray-200);
}

.atlas_steps:global(.ant-steps-small .ant-steps-item-title),
.atlas_steps:global(.ant-steps-small .ant-steps-item-description) {
  font-size: 12px;
}

.atlas_steps
  :global(
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-description
  ),
.atlas_steps
  :global(
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title
  ),
.atlas_steps
  :global(
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title
  ) {
  color: var(--blue-gray-900);
}

.atlas_steps
  :global(
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title
  ),
.atlas_steps
  :global(
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-description
  ),
.atlas_steps
  :global(
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-description
  ) {
  color: var(--blue-gray-400);
}

.atlas_steps
  :global(
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after
  ) {
  background-color: var(--dark-blue-400);
}

.atlas_steps :global(.ant-steps-item-icon .ant-steps-icon),
.atlas_steps :global(.ant-steps-item-finish .ant-steps-item-icon),
.atlas_steps :global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon) {
  color: var(--dark-blue-400);
  border-color: var(--dark-blue-400);
}

.atlas_steps :global(.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon),
.atlas_steps :global(.ant-steps-item-wait .ant-steps-item-icon) {
  border-color: var(--blue-gray-400);
  color: var(--blue-gray-400);
}

.atlas_steps :global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon) {
  background: var(--dark-blue-400);
  border-color: var(--dark-blue-400);
}
