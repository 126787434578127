.buttons .button {
  margin-left: 1rem;
}

.footer_prefix {
  display: flex;
  align-items: center;
  margin: auto;
  margin-left: 0;
  width: 100%;
}
