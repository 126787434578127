@import 'assets/css/grid.scss';

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 0 2rem;
  border-bottom: 1px solid var(--border);
  background: white;
  color: var(--text-color);
  font-weight: var(--font-med);
  box-shadow: 0 1px 0 0 var(--pitch-4);
  min-height: calc(var(--header-height) - 1px);
}

.header.product_theme {
  border-top: 0.25rem solid var(--orange-2);
}

.container {
  composes: container;
  padding: 0;
  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 2fr;
}

.app_logo {
  align-self: center;
  transform: translateX(-1rem);
  padding: 0.5rem 1rem;
}

.app_logo span {
  height: 2rem;
  width: 2rem;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0;
}

.nav :global(.ant-ribbon-wrapper) {
  margin-left: -5px;
  margin-right: 10px;
}

.nav :global(.ant-ribbon-wrapper .ant-ribbon) {
  top: -19px;
  height: 21px;
}

@media print {
  .header {
    display: none;
  }
}

.tabheader :global(.ant-tabs-ink-bar) {
  height: 5px;
  background: #f5b761;
}

.tabheader :global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab) {
  padding: 4px;
  font-size: 16px;
}

.tabheader :global(.ant-tabs-tab) {
  color: #919599;
  font-weight: 400;
}

.tabheader :global(.ant-tabs-tab-active) {
  font-weight: 600;
  color: #020d1e;
}
