.select_input {
  display: inherit;

  & :global(.ant-select-arrow) {
    pointer-events: none;
  }
}

.error {
}

.error:global(.ant-select) :global(.ant-select-selector) {
  border: 1px solid var(--red-1) !important;
}

.edited {
  & :global(.ant-select-selector) {
    background: var(--blue-5) !important;
  }
}

.highlight {
  border: 1px solid var(--red-600) !important;
  border-radius: 4px !important;
}

.collapsed_multiple_select {
  & :global(.ant-select-selection-overflow, .ant-select-selection-overflow-item) {
    display: inline-block;
  }

  & :global(.ant-select-selection-overflow .ant-select-selection-overflow-item:first-child) {
    width: calc(100% - 4px);
  }

  .multiple_label {
    max-width: calc(100% - 47px);
  }

  .tags_container {
    display: inline-block;
    width: 100%;
  }

  %tag {
    border-radius: 2px;
    padding: 0.25rem;
    font-size: 1rem;
  }

  .label_tag {
    @extend %tag;

    flex: 1;
    display: inline-flex;
    align-items: center;

    .label_content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .count_tag {
    @extend %tag;
  }
}
