.time_picker input {
  padding: 0 11px !important;
  transition: border 0.2s var(--cubic);
}

.time_picker input::placeholder {
  color: var(--pitch-3);
  opacity: 0.4;
}

.time_picker input:hover {
  border-color: var(--focus-border);
}

.time_picker input:focus {
  border-color: var(--focus-border);
  box-shadow: var(--shadow-focused);
}

.time_picker input:disabled {
  background-color: var(--color-disabled);
  border-color: var(--border);
}

.time_picker span {
  color: var(--suffix-color);
}

.icon {
  color: var(--pitch-3);

  /* position: absolute; */
  left: unset;
  right: -0.5rem;
}

.time_zone {
  margin-right: 1rem;
}
