.modal {
  /* prevent errors */
}

.modal :global(.ant-modal-header) {
  padding: 1rem 1.5rem;
}

.modal :global(.ant-modal-title) {
  font-weight: var(--font-med);
  font-size: 1rem;
}

.modal :global(.ant-modal-footer) {
  padding: 1rem;
}

.mask {
  background-color: rgb(255 255 255 / 50%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
