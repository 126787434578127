.title {
  width: 100%;
  padding: 1rem 1.5rem;
}

.container {
  min-height: 80px;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  place-items: center;
  gap: 1rem;
}

.flow {
  grid-template-columns: 1fr;
  justify-items: start;
}

.container .content {
  justify-self: start;
}

.container .button_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.icon_button {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.default {
  background-color: var(--blue-96);
  color: var(--blue-24);
}

.failed {
  background-color: var(--red-96);
  color: var(--red-24);
}

.failed.title {
  background-color: var(--red-88);
  color: var(--red-24);
}

.success {
  background-color: var(--green-96);
  color: var(--green-24);
}

.warning {
  background-color: var(--yellow-96);
  color: var(--yellow-24);
}
