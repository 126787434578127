.atlas_description_item :global(.ant-statistic-title) {
  font-size: 14px;
  color: var(--blue-gray-500);
}

.atlas_description_item :global(.ant-statistic-content) {
  font-size: 14px;
  color: var(--blue-gray-900);
}

.wrapper {
  position: relative; /* For positioning hover text */
}

.hover_text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--blue-gray-800);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.wrapper:hover .hover_text {
  visibility: visible;
  opacity: 1;
}
