@import './media.scss';
@import './variables.scss';

:root {
  --input-bg: var(--bgColor-default);
  --focus-border: var(--borderColor-textInput_focus);
  --msg-bg: rgb(62 131 245 / 6%);
  --msg-text: var(--blue-2);
  --msg-border: rgb(62 131 245 / 20%);
}

.border {
  border: 1px solid var(--border);
  transition: all 0.2s var(--cubic);
}

input,
textarea,
select {
  font-weight: var(--font-reg);
  background: var(--input-bg);
}

textarea:focus {
  outline: 0;
  border-color: var(--focus-border);
}

.textarea {
  display: block;
  border-radius: 3px;
  width: 100%;
  padding: 10px;
  composes: border;
}

.select {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  composes: border;
  background: white url('assets/svgs/directional-icons/arrow-gray.svg') no-repeat 90% 50%;
}

.select select {
  padding: 10px;
  font-size: 1rem;
  width: 130%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  appearance: none;
}

.select select:focus,
.select select:active {
  outline: none;
  border: 1px solid transparent;
}

.half {
  margin: 0 auto 1rem;
  display: flex;
}

.half > .input_group:first-child {
  margin-right: 1rem;
}

@media (--mobile) {
  .half {
    display: block;
  }
}

.heading {
  font-size: 1.25rem;
  font-weight: var(--font-med);
  margin-bottom: 1rem;
}

.section {
  margin-bottom: 3.5rem;
}
