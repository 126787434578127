.button {
  padding: 0.125rem;
}

.large {
  /* prevent errors */
}

.button :global(.anticon) {
  height: 1rem;
  width: 1rem;
}

.button.large {
  padding: 0.5rem;
}

.button.large :global(.anticon) {
  height: 1.5rem;
  width: 1.5rem;
}
