@import 'assets/css/grid.scss';

.footer {
  background: white;
  border-top: 1px solid var(--border);
  height: var(--footer-height);
}

.container {
  composes: container;
  display: grid;
  grid-template-areas: 'copyright version';
  grid-template-columns: 1fr 1fr;
}

.copyright {
  display: grid;
  grid-area: copyright;
  height: 1.25rem;
}

.version {
  display: grid;
  grid-area: version;
  text-align: right;
  font-size: 0.7rem;
  align-self: center;
}
