.multi_email {
  max-width: 100%;
  line-height: 40px;
  margin: 0 0 1rem;
  padding: 0 11px;
  flex: 1 0 auto;
  outline: 0;
  background: var(--input-bg);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--pitch-3);
  transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;
  font-size: 0.875rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}

.multi_email.focused {
  outline: 0;
  border-color: var(--focus-border);
  box-shadow: 0 0 0 2px var(--blue-5);
}

.multi_email > input {
  width: auto !important;
  height: 38px !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none;
  border: 0 none;
  display: inline-block;
  vertical-align: baseline;
}

.multi_email .item {
  height: 22px;
  line-height: 22px;
  padding: 5px 10px;
  margin-right: 10px;
  font-weight: 600;
  border: 1px solid var(--border);
  border-radius: 11px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  background-color: white;
}

.multi_email .item .email {
  max-width: 100%;
  overflow: hidden;
}

.multi_email .item:first-child {
  margin-left: 0;
}

.multi_email .item .delete_email {
  width: 1rem;
  height: 1rem;
  margin-left: 0.8em;
  cursor: pointer;
}

.multi_email input::placeholder {
  color: var(--pitch-3);
}
