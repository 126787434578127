.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.container_grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr max-content max-content;
}
