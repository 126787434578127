.atlas_badge sup {
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 8px;
  font-size: 0.75rem;
  color: var(--blue-gray-500);
  background-color: var(--blue-gray-100);
  box-shadow: none;
}
