.atlas_btn {
  border: 1px solid transparent;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: var(--font-med);
}

.atlas_icon_btn {
  padding: 0.25rem 0.5rem;
}

/* secondary button */
.atlas_btn:global(.ant-btn) {
  color: var(--blue-gray-800);
  border-color: var(--blue-gray-300);
  background: white;
}

.atlas_btn:global(.ant-btn:hover) {
  border-color: var(--blue-gray-500);
}

.atlas_btn:global(.ant-btn:focus) {
  box-shadow: 0 0 0 1px var(--dark-blue-200);
  border-color: var(--dark-blue-500);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn) {
  color: var(--red-800);
  border-color: var(--red-800);
  background: white;
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn:hover) {
  border-color: var(--red-600);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn:focus) {
  box-shadow: 0 0 0 1px var(--red-600);
  border-color: var(--red-800);
}

.atlas_btn:global(.ant-btn[disabled]),
.atlas_btn:global(.ant-btn[disabled]:hover),
.atlas_btn:global(.ant-btn[disabled]:focus),
.atlas_btn:global(.ant-btn[disabled]:active) {
  color: var(--blue-gray-300);
  border-color: var(--blue-gray-100);
  background: var(--blue-gray-100);
}

/* dashed button */
.atlas_btn:global(.ant-btn-dashed) {
  color: var(--blue-gray-800);
  border-style: dashed;
  border-color: var(--blue-gray-300);
  background: white;
}

.atlas_btn:global(.ant-btn-dashed:hover) {
  border-color: var(--blue-gray-500);
}

.atlas_btn:global(.ant-btn-dashed:focus) {
  box-shadow: 0 0 0 1px var(--dark-blue-200);
  border-color: var(--dark-blue-500);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-dashed) {
  color: var(--red-800);
  border-style: dashed;
  border-color: var(--red-800);
  background: white;
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-dashed:hover) {
  border-color: var(--red-600);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-dashed:focus) {
  box-shadow: 0 0 0 1px var(--red-600);
  border-color: var(--red-800);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-dashed[disabled]),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-dashed[disabled]:hover),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-dashed[disabled]:focus),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-dashed[disabled]:active) {
  color: var(--red-300);
  border-color: var(--red-100);
  background: var(--red-100);
}

.atlas_btn:global(.ant-btn-dashed[disabled]),
.atlas_btn:global(.ant-btn-dashed[disabled]:hover),
.atlas_btn:global(.ant-btn-dashed[disabled]:focus),
.atlas_btn:global(.ant-btn-dashed[disabled]:active) {
  color: var(--blue-gray-300);
  border-color: var(--blue-gray-100);
  background: var(--blue-gray-100);
}

/* primary button */
.atlas_btn:global(.ant-btn-primary) {
  color: white;
  border-color: var(--blue-gray-900);
  background: var(--dark-blue-900);
}

.atlas_btn:global(.ant-btn-primary:hover) {
  border-color: var(--blue-gray-700);
  background: var(--blue-gray-700);
}

.atlas_btn:global(.ant-btn-primary:focus) {
  color: white;
  border-color: var(--dark-blue-200);
  box-shadow: 0 0 0 1px var(--dark-blue-200);
  background: var(--dark-blue-900);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-primary) {
  color: white;
  border-color: var(--red-800);
  background: var(--red-800);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-primary:hover) {
  border-color: var(--red-600);
  background: var(--red-600);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-primary:focus) {
  color: white;
  border-color: var(--red-600);
  box-shadow: 0 0 0 1px var(--red-600);
  background: var(--red-800);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-primary[disabled]),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-primary[disabled]:hover),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-primary[disabled]:focus),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-primary[disabled]:active) {
  color: white;
  border-color: var(--red-200);
  background: var(--red-200);
}

.atlas_btn:global(.ant-btn-primary[disabled]),
.atlas_btn:global(.ant-btn-primary[disabled]:hover),
.atlas_btn:global(.ant-btn-primary[disabled]:focus),
.atlas_btn:global(.ant-btn-primary[disabled]:active) {
  color: white;
  border-color: var(--blue-gray-300);
  background: var(--blue-gray-300);
}

/* tertiary button */
.atlas_btn:global(.ant-btn-text) {
  color: var(--blue-gray-800);
  border-color: transparent;
  background: inherit;
}

.atlas_btn:global(.ant-btn-text:hover) {
  border-color: var(--blue-gray-100);
  background: var(--blue-gray-100);
}

.atlas_btn:global(.ant-btn-text:focus) {
  box-shadow: 0 0 0 1px var(--blue-gray-300);
  border-color: var(--dark-blue-800);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-text) {
  color: var(--red-800);
  border-color: transparent;
  background: inherit;
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-text:hover) {
  border-color: var(--red-100);
  background: var(--red-100);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-text:focus) {
  box-shadow: 0 0 0 1px var(--red-300);
  border-color: var(--red-800);
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-text[disabled]),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-text[disabled]:hover),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-text[disabled]:focus),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-text[disabled]:active) {
  color: var(--red-300);
  border-color: transparent;
  background: inherit;
}

.atlas_btn:global(.ant-btn-text[disabled]),
.atlas_btn:global(.ant-btn-text[disabled]:hover),
.atlas_btn:global(.ant-btn-text[disabled]:focus),
.atlas_btn:global(.ant-btn-text[disabled]:active) {
  color: var(--blue-gray-300);
  border-color: transparent;
  background: inherit;
}

/* link button */
.atlas_btn:global(.ant-btn-link) {
  color: var(--dark-blue-500);
  border-color: white;
  background: white;
  padding: 0 0.25rem;
}

.atlas_btn:global(.ant-btn-link:hover) {
  border-color: white;
  background: white;
}

.atlas_btn:global(.ant-btn-link:focus) {
  box-shadow: unset;
  background-color: var(--blue-gray-100);
  border-color: white;
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-link) {
  color: var(--red-500);
  border-color: white;
  background: white;
  padding: 0 0.25rem;
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-link:hover) {
  border-color: white;
  background: white;
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-link:focus) {
  box-shadow: unset;
  background-color: var(--red-100);
  border-color: white;
}

.atlas_btn:global(.ant-btn-dangerous.ant-btn-link[disabled]),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-link[disabled]:hover),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-link[disabled]:focus),
.atlas_btn:global(.ant-btn-dangerous.ant-btn-link[disabled]:active) {
  color: var(--red-300);
  border-color: white;
  background: white;
}

.atlas_btn:global(.ant-btn-link[disabled]),
.atlas_btn:global(.ant-btn-link[disabled]:hover),
.atlas_btn:global(.ant-btn-link[disabled]:focus),
.atlas_btn:global(.ant-btn-link[disabled]:active) {
  color: var(--blue-gray-300);
  border-color: white;
  background: white;
}

.atlas_btn:global(.ant-btn-sm) {
  padding: 0.25rem 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.atlas_btn:global(.ant-btn-round) {
  border-radius: 32px;
}

.atlas_btn:global(.ant-btn-sm.ant-btn-round) {
  border-radius: 24px;
}

.atlas_btn:global(.ant-btn-circle) {
  min-width: 32px;
  border-radius: 50%;
}

.atlas_btn:global(.ant-btn-sm.ant-btn-circle) {
  min-width: 24px;
}
