.progress_line {
  padding: 0.5rem;
}

.progress_line_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pitch-3);
  border-radius: 1rem;
  color: #fff;
  font-size: 10px;
  height: 1rem;
  width: 1rem;
}

.visited .progress_line_dot {
  background-color: var(--blue-2);
}

.failed .progress_line_dot {
  background-color: var(--red-56);
}

.progress_line :global(.ant-timeline-item-tail) {
  background-color: var(--pitch-4);
  border: none;
  border-radius: 6px;
  height: calc(100% - 2.2rem);
  left: 2.7px;
  top: 1.5rem;
  width: 0.25rem;
}

.progress_line .visited :global(.ant-timeline-item-tail) {
  background-color: var(--blue-3);
}

.next_failed {
}

.progress_line .next_failed :global(.ant-timeline-item-tail) {
  background-color: var(--red-56);
}

.progress_line :global(.ant-timeline-item.ant-timeline-item-last) {
  padding-bottom: 0;
}

.progress_line :global(.ant-timeline-item-content) {
  top: -5.1px;
}

.progress_line :global(.ant-timeline-item-last > .ant-timeline-item-content) {
  min-height: auto;
}
