.atlas_list {
  color: var(--blue-gray-700);
}

.atlas_list:global(.ant-list-bordered) {
  border-color: var(--blue-gray-300);
}

.atlas_list:global(.ant-list-split .ant-list-header) {
  border-color: var(--blue-gray-200);
}

.atlas_list:global(.ant-list-split .ant-list-item) {
  border-color: var(--blue-gray-200);
}

/* vertical style */
.atlas_list:global(.ant-list-vertical .ant-list-item-meta-title) {
  font-size: 14px;
  margin-bottom: 0;
}

/* AtlasListItem */
.atlas_list_item {
  color: var(--blue-gray-700);
}

/* AtlasListItemMeta */
.atlas_list_item_meta :global(.ant-list-item-meta-title) {
  color: var(--blue-gray-900);
}

.atlas_list_item_meta :global(.ant-list-item-meta-description) {
  color: var(--blue-gray-400);
}
