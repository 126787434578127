/*
  Common CSS classes that can be used either by directly importing this file from Javascript,
  or by importing this from another CSS file and using `composes`.
 */

a.link {
  text-decoration: underline var(--blue-4);
  color: var(--blue-2);
  transition: color 0s; /* Override transition set in ant's base styles */
}

a.link:hover {
  text-decoration: underline var(--blue-2);
  color: var(--blue-2);
}

a.link:focus {
  background: var(--blue-5);
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
