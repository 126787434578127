.atlas_input_number {
  width: 100%;
  border-color: var(--blue-gray-200);
}

.atlas_input_number:global(.ant-input-number):hover,
.atlas_input_number:global(
    .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled)
  ):hover {
  border-color: var(--blue-gray-800);
}

.atlas_input_number:global(.ant-input-number:focus),
.atlas_input_number:global(.ant-input-number-focused),
.atlas_input_number:global(.ant-input-number-affix-wrapper:focus),
.atlas_input_number:global(.ant-input-number-affix-wrapper-focused) {
  border-color: var(--blue-gray-800);
  box-shadow: 0 0 0 2px var(--blue-gray-300);
}

/* Disabled */
.atlas_input_number:global(.ant-input-number-disabled),
.atlas_input_number:global(.ant-input-number-affix-wrapper-disabled) {
  color: var(--blue-gray-400);
  background: var(--blue-gray-50);
}

/* Error */
.atlas_input_number:global(
    .ant-input-number-status-error:not(
        .ant-input-number-disabled,
        .ant-input-number-borderless
      ).ant-input-number
  ),
.atlas_input_number:global(
    .ant-input-number-status-error:not(
        .ant-input-number-disabled,
        .ant-input-number-borderless
      ).ant-input-number
  ):hover,
.atlas_input_number:global(
    .ant-input-number-affix-wrapper-status-error:not(
        .ant-input-number-affix-wrapper-disabled,
        .ant-input-number-affix-wrapper-borderless
      ).ant-input-number-affix-wrapper
  ),
.atlas_input_number:global(
    .ant-input-number-affix-wrapper-status-error:not(
        .ant-input-number-affix-wrapper-disabled,
        .ant-input-number-affix-wrapper-borderless
      ).ant-input-number-affix-wrapper
  ):hover {
  border-color: var(--red-600);
}

.atlas_input_number:global(.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix) {
  color: var(--red-600);
}
