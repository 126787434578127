.content {
  width: 100%;
  padding: 0.5rem 0;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
