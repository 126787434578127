.warning {
  :global(.ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content) {
    background: var(--yellow-2) !important;
  }

  :global(.ant-popover-inner) {
    border: solid 2px var(--yellow-2);
  }
}

.error {
  :global(.ant-popover-inner) {
    border: solid 2px var(--red-2);
    color: var(--red-2);
  }

  :global(.ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content) {
    background: var(--red-2) !important;
  }
}
