.image_viewer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.image_wrapper {
  position: absolute;
  height: 100%;
  inset: 0;
  text-align: center;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.image_wrapper img {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
}

.image_wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}

.image_viewer .viewer_operation {
  position: absolute;
  right: 1rem;
  bottom: 1.5rem;
}

.image_viewer .viewer_operation button {
  padding: 7px;
  display: block;

  /* width: 2.5rem; */
  border-radius: 5px;
  margin-top: 1rem;
}
