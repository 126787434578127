.notes {
  position: relative;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--border);
  background-color: white;
}

.notes:hover {
  background-color: var(--pitch-5);
}

.notes:hover .edit {
  display: block;
}

.notes_activated {
  position: relative;
  padding: 0.5rem 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid var(--border);
  background-color: var(--blue-5);
}

.input_line {
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-column-gap: 1rem;
  align-items: center;
}

.input_line_activated {
  grid-template-columns: 1fr;
}

.label_line {
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-column-gap: 1rem;
  align-items: center;
}

.label_line_activated {
  grid-template-columns: 1fr;
}

.inline_mode_input_line {
  display: grid;
  font-size: 1rem;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  align-items: center;
}

.small_skeleton {
  line-height: 24px;
}

.small_skeleton :global(.ant-skeleton-title) {
  margin-top: 4px;
  margin-bottom: -4px;
}

.default_skeleton {
  line-height: 30px;
}

.default_skeleton :global(.ant-skeleton-title) {
  margin-top: 8px;
}

.small_skeleton :global(.ant-skeleton-paragraph),
.default_skeleton :global(.ant-skeleton-paragraph) {
  display: none;
}

.label {
  padding-right: 1rem;
  line-height: 24px;
  font-weight: var(--font-bold);
  font-size: 1rem;
}

.sub_label {
  font-weight: var(--font-reg);
  color: #567;
}

.textarea,
.textarea:hover {
  border-color: transparent;
  background-color: transparent;
  resize: none;
  font-size: 1rem;
}

.vertical_mode_textarea {
  position: relative;
  left: -0.75rem;
}

.textarea_activated {
  resize: none;
  font-size: 1rem;
}

.small_input {
  padding: 0 11px;
  min-height: 24px !important;
  line-height: 22px !important;
}

.default_input {
  padding: 4px 11px;
  min-height: 34px !important;
  line-height: 24px !important;
}

.operation_line {
  margin-top: 0.5rem;
  margin-right: -1px;
  padding: 0 0.5rem;
  border: 1px solid var(--border);
  border-top: none;
  position: absolute;
  right: 0;
  background-color: var(--blue-5);
}

.edit {
  display: none;
  color: var(--pitch-3);
}

.check {
  color: var(--green-1);
  margin-right: 0.5rem;
}

.close {
  color: var(--red-1);
}
