.table {
  width: 100%;
  background: white;
  border: 1px solid var(--border);
  table-layout: fixed;
}

.table th,
.table td {
  padding: 1rem;
  text-align: center;
}

.table td {
  border-top: 1px solid var(--border);
}

.table th {
  background: white;
  font-weight: var(--font-bold);
}

.table thead th,
.table tbody td {
  width: 10%;
  text-align: left;
}

.table thead th:last-child,
.table tbody td:last-child {
  padding-right: 2rem;
}

.table tr:hover {
  background-color: var(--pitch-5);
}

.table + .table_pagination {
  text-align: right;
}

.table + .table_empty {
  margin-top: 10vh;
}

/* Override default antD's striped colors with more css specificity */
.table tr:nth-child(odd):hover {
  background-color: var(--pitch-5);
}

.table .sub_row {
  background-color: var(--pitch-5);
}

.table .sub_row td:first-child {
  padding-left: 2rem;
}

.table .group_head {
  background-color: var(--bg-light);
}

.table .group_head td {
  padding-top: 0;
  padding-bottom: 0;
}

/* Layout Mode: Compact: start */
.compact_table {
  table-layout: auto;
}

.compact_table th,
.compact_table td {
  padding: 0.5rem 1rem;
  text-align: center;
}

.compact_table th,
.compact_table td,
.compact_table td a {
  font-size: 1rem;
  line-height: 1.5rem;
}

.compact_table thead th,
.compact_table tbody td {
  width: auto;
}

.compact_table thead th:first-child,
.compact_table tbody td:first-child {
  padding-left: 1.5rem;
}

/* Layout Mode: Compact: end */

/* Column Width Class: start */
.table tbody td.vw5 {
  min-width: 5vw;
}

.table tbody td.vw10 {
  min-width: 10vw;
}

.table tbody td.vw15 {
  min-width: 15vw;
}

.table tbody td.vw20 {
  min-width: 20vw;
}

.table tbody td.vw25 {
  min-width: 25vw;
}

.table tbody td.vw30 {
  min-width: 30vw;
}

.table tbody td.vw35 {
  min-width: 35vw;
}

/* Column Width Class: end */
