.table {
}

.table :global(.ant-table-filter-dropdown .ant-checkbox-wrapper) {
  display: inline-flex !important;
}

.table :global(.ant-table-thead > tr > th) {
  background: white;
  font-family: Averta, sans-serif;
  font-style: normal;
  font-weight: var(--font-med);
  font-size: var(--font-size-reg);
  border-top: 1px solid var(--pitch-88);
  border-bottom: 1px solid var(--pitch-88);
}

.table
  :global(
    .ant-table-thead
      > tr
      > th:not(
        :last-child,
        .ant-table-selection-column,
        .ant-table-row-expand-icon-cell,
        [colspan]
      )::before
  ) {
  background-color: var(--pitch-88);
}

.table :global(.ant-pro-card-body) {
  padding: 0;
}

.table :global(.ant-pagination) {
  padding: 1rem !important;
}

.table :global(.ant-pagination li) {
  border: 1px solid var(--pitch-88) !important;
  color: #333d48;
  font-size: 1rem;
  height: 2rem !important;
  line-height: 2rem !important;
  margin-left: 0.5rem !important;
  min-width: 2rem !important;
}

.table :global(.ant-pagination .ant-pagination-item-active) {
  background: var(--dark-blue-800);
  border: 1px solid var(--dark-blue-800);
}

.table :global(.ant-pagination .ant-pagination-item-active a) {
  color: white !important;
}

.table :global(.ant-pagination li:first-child) {
  border: none !important;
}

.table :global(.ant-table-tbody > tr > td) {
  border-bottom: 1px solid var(--pitch-88);
}

.table :global(.ant-table-cell) {
  font-family: Averta, sans-serif;
  font-style: normal;
  font-weight: var(--font-reg);
  font-size: var(--font-size-reg);
  line-height: 2rem;
  color: var(--pitch-40);
  vertical-align: baseline;
}

.table :global(.ant-table-cell .ant-checkbox-wrapper) {
  display: inline-flex;
}

.table :global(.ant-table-cell > span) {
  font-family: Averta, sans-serif;
  font-style: normal;
  font-weight: var(--font-reg);
  font-size: var(--font-size-reg);
  line-height: 2rem;
  color: var(--pitch-40);
}

.table :global(.ant-pro-table-list-toolbar) {
  padding: 0 1rem;
}

.table :global(.ant-table-thead > tr > th),
:global(.ant-table-tbody > tr > td),
:global(.ant-table tfoot > tr > th),
:global(.ant-table tfoot > tr > td) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.table :global(.ant-table.ant-table-middle .ant-table-thead > tr > th),
:global(.ant-table.ant-table-middle .ant-table-tbody > tr > td),
:global(.ant-table.ant-table-middle tfoot > tr > th),
:global(.ant-table.ant-table-middle tfoot > tr > td) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.table :global(.ant-table.ant-table-small .ant-table-thead > tr > th),
:global(.ant-table.ant-table-small .ant-table-tbody > tr > td),
:global(.ant-table.ant-table-small tfoot > tr > th),
:global(.ant-table.ant-table-small tfoot > tr > td) {
  padding-top: 0;
  padding-bottom: 0;
}

.table :global(.ant-table-tbody > tr.ant-table-row-selected > td) {
  background: white;
}

.table
  :global(.ant-pro-table .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner),
:global(.ant-pro-table .ant-checkbox-checked .ant-checkbox-input:hover + .ant-checkbox-inner),
:global(
    .ant-pro-table .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled),
    .ant-checkbox-inner
  ),
:global(
    .ant-pro-table .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled),
    .ant-checkbox-inner:hover
  ) {
  background-color: var(--pitch-24) !important;
  border: 1px solid var(--pitch-24) !important;
}

.table :global(.ant-pro-table .ant-checkbox .ant-checkbox-inner:hover),
.table :global(.ant-pagination-item-active),
.table :global(.ant-pagination-item-active:hover) {
  border: 1px solid var(--pitch-24);
}

.table :global(.ant-pagination-item-active a) {
  color: var(--pitch-24);
}

.table :global(.ant-checkbox-indeterminate .ant-checkbox-inner::after) {
  background-color: var(--pitch-24);
}

:global(.ant-pro-table-column-setting-overlay .ant-popover-inner) {
  border: none !important;
}

.table :global(.ant-table-column-sorter) {
  line-height: 0;
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
}

.filter_icon {
  width: 1rem;
  height: 1rem;
}

.table :global(.ant-table-column-sorters:hover .ant-table-column-sorter) {
  background: rgb(0 0 0 / 4%) !important;
  color: rgb(0 0 0 / 45%) !important;
}

.table :global(.ant-table-filter-trigger) {
  margin: 0 -0.5rem 0 0.25rem;
}

.table :global(.ant-table-content::-webkit-scrollbar) {
  appearance: none;
  height: 10px;
}

.table :global(.ant-table-content::-webkit-scrollbar-thumb) {
  border-radius: 5px;
  background-color: var(--pitch-88);
}

.table :global(.ant-pagination-options .ant-select .ant-select-selector),
.table
  :global(
    .ant-pagination-options .ant-select-focused:not(.ant-select-disabled) .ant-select-selector
  ) {
  border: none !important;
  background-color: white !important;
}

.pagination_select {
}

.pagination_select :global(.ant-select-selector) {
  border: 1px solid var(--pitch-88) !important;
  color: #333d48;
  font-size: 1rem;
  height: 100% !important;
  line-height: 100% !important;
  top: -1px;
}

.pagination_select :global(.ant-select-selection-item) {
  background-color: white !important;
}

.reset_all {
  white-space: nowrap;
}
