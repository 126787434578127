.no_padding {
  padding: 0;
}

.shipment_list {
  padding: 1rem;
  background-color: #fff;
}

.shipment_list_grouping {
  & :global(.ant-tabs-extra-content) {
    margin-right: 1.5rem;
  }

  & :global(.ant-tabs-content-holder) {
    margin-top: 1rem;
  }

  & :global(.ant-tabs-tab.ant-tabs-tab-disabled:not(.ant-tabs-tab-active)) sup {
    color: var(--blue-gray-300);
  }
}

.tab_content {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  .close_tab_icon {
    margin: 0.25rem;
    font-size: 0.75rem;
    padding: 2px;
    border-radius: 2px;
    display: inline-block;
    transition: background-color 0.3s;
  }

  &:hover .close_tab_icon {
    background: var(--indigo-300);
  }
}

.shipment_list .select_actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

/* Shipment List Table */
.shipment_list .operation_icon {
  width: 1rem;
  height: 1rem;
}

.personalized_view_preset_filters_content {
  margin-left: 0.5rem;
  color: var(--blue-gray-500);

  .preset_filter_field {
    font-weight: var(--font-med);
    margin: 0 0.25rem;
  }
}

.shipment_search_button {
  padding: 8px 12px;
}

.shipment_search_button :global(.anticon) {
  height: 14px;
}

.shipment_list :global(.ant-pro-table-alert) {
  display: block !important;
  background: transparent;
}

@media screen and (width > 1580px) {
  .shipment_list :global(.ant-pro-table-alert) {
    margin-top: -3rem;
  }
}

.shipment_list :global(.ant-pro-table-alert .ant-alert.ant-alert-no-icon) {
  border: none;
  padding: 0;
  background: transparent;
}

.shipment_list .shipment_list_filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.shipment_list .shipment_list_filter :global(.ant-radio-button-wrapper) {
  margin-bottom: 8px;
}

.shipment_list .shipment_list_table {
  width: 100%;
  position: relative;
  top: 8px;
}

.shipment_list_table :global(.ant-table-cell) {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.shipment_list_table :global(.ant-table-body) {
  padding-bottom: 3rem;
}

:global(.ant-pro-table-column-setting-overlay .ant-popover-inner-content) {
  width: 280px !important;
}

.shipment_list_table :global(.ant-table.ant-table-middle .ant-table-thead > tr > th) {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.shipment_list .shipment_list_table table tbody td {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  font-size: 14px !important;
  line-height: 1.25rem !important;
  vertical-align: middle;
}

.shipment_list_table table tbody td :global(.ant-typography) {
  color: var(--pitch-40);
}

.shipment_list_table table tbody td :global(.ant-typography.ant-typography-ellipsis) {
  margin: 0;
}

.shipment_list_table
  table
  tbody
  td
  :global(.ant-typography.ant-typography-ellipsis a.ant-typography-expand) {
  color: var(--dark-blue-500);
}

.shipment_list_table
  table
  tbody
  td
  :global(.ant-typography.ant-typography-ellipsis a.ant-typography-expand:hover) {
  text-decoration: underline;
}

.shipment_list_table
  table
  tbody
  td
  :global(.ant-typography.ant-typography-ellipsis a.ant-typography-expand:focus) {
  color: var(--dark-blue-600);
}

.shipment_list_table :global(.ant-table-thead > tr > th) {
  background: var(--blue-gray-50);
  font-weight: var(--font-med);
  vertical-align: middle;
}

.shipment_list_table :global(.ant-pro-table-list-toolbar) {
  padding-left: 0 !important;

  :global(.ant-pro-table-list-toolbar-container) {
    :global(.ant-pro-table-list-toolbar-right) {
      flex-grow: 1;
    }

    :global(.ant-space-item) {
      display: flex;
    }

    :global(.ant-space-item:has(> .ant-pro-table-list-toolbar-filter)) {
      flex-grow: 1;
    }
  }
}

.shipment_list_table :global(.ant-table-row-selected .ant-table-cell) {
  background: var(--pitch-96) !important;
}

.shipment_list_table .shipment_list_tag {
  font-size: 10px !important;
  font-weight: var(--font-med) !important;
}

.shipment_list_table .inline_edit,
.shipment_list_table .inline_edit_date_time {
  width: 150px;
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.shipment_list_table .inline_edit_date_time {
  width: 180px;
}

.shipment_list_table .shipment_list_container_icon {
  display: inline-block;
  height: 16px;
  width: 20px;
}

.shipment_list_table .shipment_list_container_icon.hot {
  color: var(--red-600);
}

.shipment_list_table .shipment_list_container_number {
  font-weight: var(--font-med);
  line-height: 24px;
}

.shipment_list_table :global(.ant-table-pagination) {
  position: sticky;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--pitch-88);
  background: white;
  margin: 0 !important;
  z-index: 10;
  padding: 16px;
}

.shipment_list_table :global(.ant-pro-table-search.ant-pro-table-search-query-filter) {
  margin: 0;
  padding: 24px 4px 0;
}

.shipment_list_table :global(.ant-pro-card.ant-pro-table-search) {
  top: 0;
}

.shipment_list_table :global(.ant-table-filter-trigger) {
  margin-left: 0 !important;
  padding-left: 0;
}

.shipment_list_table :global(.ant-table-cell-fix-left) div[aria-label='Edit View Buttons'],
.shipment_list_table :global(.ant-table-cell-fix-right) div[aria-label='Edit View Buttons'] {
  position: relative;
  justify-content: flex-end;
}

.search_form_empty_container {
  margin-top: 0;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.search_form_container {
  margin-top: 8px;
  width: 100%;
}

.search_form_container :global(.ant-row) {
  display: flex !important;
}

.search_form_editor {
  display: inline-flex;
  justify-content: flex-end;
}

.search_form_field {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.search_form_active_field {
  color: var(--dark-blue-500) !important;
}

/* stylelint-disable */
.shipment_list_journal_entry_input {
  min-height: 200px;
  width: 452px;
  max-width: 452px;
  background: white;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #dbe0e5;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
/* stylelint-enable */

.shipment_list_journal_entry_input .shipment_list_journal_entry_content {
  flex: 1 0 auto;
}

.shipment_list_journal_entry_input .shipment_list_journal_entry_content p {
  font-size: 0.75rem !important;
  line-height: 1rem;
  font-weight: var(--font-reg);
  padding: 0 0 8px;
  margin: 0;
}

.shipment_list_journal_entry_input .shipment_list_journal_entry_footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.shipment_list_journal_entry_input .shipment_list_journal_entry_footer button:not(:last-child) {
  margin-right: 12px;
}

.cancel_shipment_modal {
  /* prevent error */
}

.cancel_shipment_modal :global(.ant-modal-header) {
  padding: 16px 24px;
}

.cancel_shipment_modal :global(.ant-modal-title) {
  font-size: 14px;
}

.cancel_shipment_modal :global(.ant-modal-footer) {
  padding: 16px;
}

.cancel_shipment_confirm_button {
  margin-left: 16px !important;
}

.cancel_progress_bar {
  /* prevent error */
}

.cancel_progress_bar p {
  padding-top: 0;
}

.pagination_info_container {
  position: absolute;
  left: 16px;
  top: 14px;
  display: flex;
  align-items: center;
}

.select_all_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear_selection {
  margin-left: 8px;
  padding: 4px;
}

.select_all,
.select_all:focus {
  padding: 8px;
  user-select: none;
  color: var(--blue-gray-800) !important;
}

a.select_all {
  text-decoration: underline;
}

.disabled_select_all_children {
  display: inline-block;
}

.select_all_tooltip_container {
  display: inline-block;
  cursor: not-allowed;
}

.select_all_tooltip_container button {
  pointer-events: none;
}

.integration_skeleton ul {
  margin: 0;
}

.integration_container {
  width: 100%;
  height: 100%;
}

.unavailable_icon svg path {
  fill: var(--blue-gray-300);
}

.integration_container:first-child {
  position: relative;
  top: 4px;
}

.integration_list {
  gap: 0 !important;
}

.start_location_status {
  width: 13.5px;
  height: 13.5px;
  position: relative;
  margin-right: 0.5rem;
  top: 2px;
}

.automation_failed svg,
.automation_needs_data svg {
  transform: scale(1.34, 1.34);
}

.automation_processing {
  color: var(--dark-blue-500);
  top: 3px;
}

.automation_booked {
  color: var(--green-500);
}

.automation_failed {
  color: var(--red-800);
}

.automation_needs_data {
  color: var(--amber-600);
}

.load_start_location_container {
  width: 100%;
}

.load_start_location_container .location_start_location {
  width: calc(100% - 18px) !important;
}

.shipment_list_create_new {
  padding-right: 6px;
}

.sync_icon {
  font-size: 18px;
  margin-left: 2px;
}

.appointment_match_icon svg path {
  fill: var(--green-600);
}

.appointment_mismatch_icon svg path {
  fill: var(--red-500);
}

.appointment_partially_match_icon svg path {
  fill: var(--amber-500);
}

/* shipment journal entries popover */
.journal_entry_list {
  width: 20rem;
  max-height: 25rem;
  overflow: hidden;
  overflow-y: auto;
}

.journal_entry_read_view {
  min-width: 8rem;
  cursor: cell !important;
}

.journal_entry_text_area {
  min-width: 350px;
}

/* Common Class Name */
.expired_time {
  padding: 0.25rem 0;
  color: var(--red-800) !important;
  background-color: var(--red-100);
}

.failure_indicator {
  padding: 0.25rem 0;
  color: var(--red-600) !important;
}

.success_indicator {
  padding: 0.25rem 0;
  color: var(--green-800) !important;
}

.within_two_days {
  padding: 0.25rem 0;
  color: var(--amber-500) !important;
  background-color: var(--amber-50);
}

.close_icon {
  color: var(--red-600);
  margin-right: 8px;
}

.hold_reason_list {
  ul li {
    padding: 4px 0 !important;
  }

  .hold_reason_item_space {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .hold_reason_applied_at {
    color: var(--blue-gray-400);
  }

  :global(.ant-list-empty-text) {
    padding: 0;
    text-align: left;
    color: var(--blue-gray-700);
  }
}

.shipment_export_button {
  margin-left: 8px;
}

.shipment_export_icon {
  width: 1.25rem;
  height: 1.25rem;
}

.otr_status {
  font-size: 10px;
}

.hover_container_style {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}
