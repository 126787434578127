.menu {
  box-shadow:
    0 6px 12px -4px rgb(0 0 0 / 12%),
    0 16px 32px -8px rgb(0 0 0 / 12%) !important;
  border: 2px solid var(--pitch-3);
  border-radius: 3px;
  padding: 0.5rem;
  min-width: 200px;
}

.menu > li {
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 1rem;
  font-size: 14px;
  height: auto;
  margin: 0;
  padding: 0.5rem;
  color: var(--pitch-2);
  border-radius: 3px;
}

.menu > li:hover,
.menu > li:focus {
  background: var(--pitch-5);
}

.menu > li + li {
  margin-top: 4px;
}

.link {
  line-height: 1rem;
  padding: 0;
  margin: 0;
}

a.link:hover,
a.link:focus {
  color: var(--pitch-2);
}
