.atlas_card {
  border-color: var(--blue-gray-200);
  border-radius: 3px;
}

.atlas_card:global(.ant-card-small .ant-card-head),
.atlas_card:global(.ant-card-small .ant-card-body) {
  padding: 0 1rem;
}

.atlas_card :global(.ant-card-head) {
  border-bottom: none;
}

.atlas_card .card_preset_space {
  display: flex;
  padding-bottom: 1rem;
}

.atlas_card .description_list_space {
  flex-wrap: wrap;
  row-gap: 0.5rem;
}

.atlas_card .card_tab_pane {
  padding-top: 1rem;
}
