.slide_panel {
  background: transparent;
}

.slide_collapse_button {
  position: absolute;
  right: 0;
  top: 0.5rem;
}

.hidden {
  display: none;
}

.panel_card {
  border-top: none;
  border-color: var(--blue-gray-200);
  border-radius: 3px;
  height: 100%;
}

.panel_card :global(.ant-card-head-title) {
  font-weight: 600;
  color: var(--blue-gray-900);
}

.panel_card :global(.ant-card-body) {
  padding: 0;
}
