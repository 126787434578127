.menu {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  width: calc(var(--atlas-width-expanded) - 3rem);
  height: calc(100% - 2rem);
  background: var(--pitch-1);
  color: white;
  z-index: 20;
  box-shadow: 0 0.25rem 0.5rem var(--bg-soft);
  display: grid;
  grid-gap: 1rem;
  font-size: var(--font-size-lrg);
  grid-template-rows: auto 1fr;
  transition: all 0.1s var(--cubic);
}

.nav {
  overflow-y: auto;
}

@media (--mobile-med) {
  .nav {
    overflow-y: scroll;
  }
}

.title {
  font-weight: var(--font-med);
  color: var(--pitch-3);
  padding: 0;
}

.link {
  color: var(--pitch-3);
  font-size: var(--font-size-lrg);
  font-weight: var(--font-med);
  padding: 0.5rem 0;
  background: none;
  transition: all var(--duration-short) var(--cubic);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link:hover,
.link:focus {
  color: white;
}

.small {
  font-size: var(--font-size-sml);
  font-weight: var(--font-reg);
}

.atlas_links {
  align-self: end;
}

.hr {
  height: 2px;
  border: none;
  background: var(--pitch-3);
  width: 4rem;
  margin: 1rem 0;
}

.user {
  font-weight: var(--font-reg);
  color: white;
  margin-left: 30%;
  margin-right: 10%;
}

.sign_out {
  display: block;
  background: none;
  border: none;
  max-width: 100%;
  width: 100%;
  padding: 0.25rem 0;
  font-weight: var(--font-med);
  color: var(--pitch-3);
  text-align: left;
  cursor: pointer;
}

.sign_out:focus {
  outline: 0;
}

.user_details_controls {
  margin-top: 20px;
  margin-bottom: 60px;
}

.profile_details_section {
  display: inline-block;
  background: #3d4dac;
  border: 1px solid #2b3679;
  border-radius: 50%;
  width: 32px !important;
  height: 32px !important;
  line-height: 28.287px;
  padding: 1.5px !important;
  text-align: center !important;
}

.profile_details_section:hover {
  cursor: pointer;
  background: #33418f;
  border: 1px solid #222a5f;
}

.profile_details_link {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px !important;
  color: white;
}

.profile_details_link:hover {
  color: white;
}
