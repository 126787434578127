div.atlas_alert {
  border-radius: 3px;
  padding: 0.5rem 1rem;
  color: var(--blue-gray-700);
}

div.atlas_alert :global(.ant-alert-message) {
  color: var(--blue-gray-700);
}

/* Success Type */
.atlas_alert:global(.ant-alert-success) {
  background-color: var(--green-100);
  border: 1px solid var(--green-500);
}

.atlas_alert:global(.ant-alert-success) > span:global(.ant-alert-icon) {
  color: var(--green-500);
}

/* Warning Type */
.atlas_alert:global(.ant-alert-warning) {
  background-color: var(--amber-100);
  border: 1px solid var(--amber-500);
}

.atlas_alert:global(.ant-alert-warning) > span:global(.ant-alert-icon) {
  color: var(--amber-500);
}

/* Info Type */
.atlas_alert:global(.ant-alert-info) {
  background-color: var(--dark-blue-50);
  border: 1px solid var(--dark-blue-500);
}

.atlas_alert:global(.ant-alert-info) > span:global(.ant-alert-icon) {
  color: var(--dark-blue-500);
}

/* Error Type */
.atlas_alert:global(.ant-alert-error) {
  background-color: var(--red-100);
  border: 1px solid var(--red-500);
}

.atlas_alert:global(.ant-alert-error) > span:global(.ant-alert-icon) {
  color: var(--red-500);
}
