.atlas_radio_button {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: var(--font-med);
  line-height: 16px;
}

.atlas_radio_group {
}

.round:global(.ant-radio-button-wrapper),
.group_with_round_button :global(.ant-radio-button-wrapper) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 32px !important;
  white-space: nowrap;
  padding: 8px 16px !important;
  border: 1px solid var(--blue-gray-300);
  border-radius: 16px !important;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: var(--font-med);
  color: var(--blue-gray-700);
  line-height: 16px;
}

.radio_button_count {
}

.atlas_radio_button:global(.ant-radio-button-wrapper-disabled) {
  color: var(--blue-gray-300);
  background-color: var(--blue-gray-50);
}

.atlas_radio_button:global(.ant-radio-button-wrapper-disabled):not(
    :global(.ant-radio-button-wrapper-checked)
  )
  .radio_button_count
  sup {
  color: var(--blue-gray-300);
}

:global(.ant-radio-group-small) .round:global(.ant-radio-button-wrapper),
.group_with_round_button:global(.ant-radio-group-small) :global(.ant-radio-button-wrapper) {
  height: 24px !important;
  padding: 4px 16px !important;
  border-radius: 12px;
}

.round:global(.ant-radio-button-wrapper:not(:last-child)),
.group_with_round_button :global(.ant-radio-button-wrapper:not(:last-child)) {
  margin-right: 8px;
}

.round:global(.ant-radio-button-wrapper::before),
.group_with_round_button :global(.ant-radio-button-wrapper::before) {
  display: none;
}

.round:global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked),
.group_with_round_button :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked) {
  color: white;
  background-color: var(--dark-blue-900);
  border-color: var(--dark-blue-900) !important;
}

.round:global(.ant-radio-button-wrapper:focus-within),
.group_with_round_button :global(.ant-radio-button-wrapper:focus-within) {
  box-shadow: unset;
}
