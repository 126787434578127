@import 'assets/css/forms.scss';

.input {
  width: 100%;
  padding: 0 11px !important;
  line-height: 38px !important;
  display: block;
  border-radius: 4px;
  box-shadow: none;
  color: var(--pitch-1);
  font-size: 1rem;
  composes: border;
}

.input[disabled] {
  color: var(--suffix-color);
}

.input_icon {
  padding-left: 2rem !important;
}

.input_icon_right {
  /* prevent lint errors */
}

.input_icon.input_icon_right {
  padding-left: 10px;
  padding-right: 2rem;
}

.input::placeholder {
  color: var(--pitch-3);
  opacity: 0.4;
}

.input:hover {
  border-color: var(--focus-border);
}

.input:focus {
  outline: none;
  border-color: var(--focus-border);
  box-shadow: var(--shadow-focused);
  background: white;
}

:global(.ant-form-item-has-error) .input,
.input.error {
  border-color: var(--red-1);
}

.input.disabled,
.btn.disabled {
  cursor: not-allowed;
  background: var(--pitch-5);
}

.input.edited {
  background: var(--blue-5);
}

.input.hide {
  display: none;
}

.input_group {
  text-align: left;
  margin-bottom: 1rem;
  position: relative;
}

.input_group.warning input {
  background-color: var(--bgColor-warning);
  border-color: var(--borderColor-warning);
}

.input_group.no_padding {
  margin: 0;
}

.input_group .input_element,
.input_element input {
  background-color: var(--input-bg);
  padding: 0.6rem;
  height: auto;
}

.input_group :global(.ant-tooltip) {
  max-width: 100%;
}

.icon_label {
  position: absolute;
  top: 3rem;
  left: 0.5rem;
  font-size: 1rem;
}

.icon_no_label {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
}

.icon_right {
  /* prevent errors */
}

.icon_label.icon_right,
.icon_no_label.icon_right {
  left: unset;
  right: 0.5rem;
}

.input_group .label {
  display: inline-block;
}

.label {
  display: block;
}

.label.error {
  color: var(--red-1);
}

.label.error + :global(.ant-select .ant-select-selector),
.label.error + :global(.ant-picker) {
  border-color: var(--red-1);
}

.msg {
  color: var(--red-1);
  padding: 0;
  font-weight: var(--font-med);
}

.limit {
  position: absolute;
  bottom: 0.5rem;
  right: 1px;
  padding: 0 0.75rem;
  color: var(--textColor-muted);
  background: var(--bgColor-default);
}

.edited_input_limit {
  background: var(--blue-5) !important;
}

.limit.input_limit {
  padding-right: 0;
  margin-right: 1.1rem;
}

.input:focus + .limit {
  background: white;
}

.tooltip {
  /* left blank due to eslint bug https://github.com/atfzl/eslint-plugin-css-modules/issues/14 */
}

.tooltip :global(.ant-tooltip-inner) {
  background-color: var(--red-1);
}

.tooltip :global(.ant-tooltip-arrow > .ant-tooltip-arrow-content) {
  background-color: var(--red-1);
}

.show_limit {
  padding: 0.75rem 0.75rem 2rem;
}

.secondary_label {
  color: var(--pitch-3);
  font-size: 0.875rem;
  margin-left: 0.5rem;
  display: inline-block;
  padding-top: 2px;
}

.undo_icon {
  transform: scaleX(-1);
}

.highlight {
  border-color: var(--red-600);
}
