.temp_container {
  display: flex;
}

.temp_label {
  display: inline-block;
  height: 40px;
}

.temp_type_toggle {
  margin: 0;
}

.temp_type_toggle :global(.ant-select > .ant-select-selector) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background: var(--blue-gray-200) !important;
  border-width: 1px;
}

.temp_type_toggle:hover :global(.ant-select > .ant-select-selector) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0;
}

.temp_input {
  width: 100%;
}

.temp_input input {
  text-align: right;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.error {
}

.error label {
  color: var(--red-1);
}

.error input {
  border-color: var(--red-1);
}

.tooltip {
}

.tooltip :global(.ant-tooltip-inner) {
  background-color: var(--red-1);
}

.tooltip :global(.ant-tooltip-arrow > .ant-tooltip-arrow-content) {
  background-color: var(--red-1);
}
